define("ember-simple-auth/utils/location", ["exports", "@babel/runtime/helpers/esm/defineProperty"], function (_exports, _defineProperty2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class LocationUtil {
    constructor() {
      (0, _defineProperty2.default)(this, "location", window.location);
    }
    replace(...args) {
      this.location.replace(...args);
    }
  }
  const location = new LocationUtil();
  var _default = _exports.default = location;
});