define("ember-responsive/helpers/media", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember/component/helper", "@ember/service", "@ember/object"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _helper, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  let MediaHelper = _exports.default = (_dec = (0, _service.inject)(), _class = class MediaHelper extends _helper.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "media", _descriptor, this);
      this.media.on('mediaChanged', () => {
        this.recompute();
      });
    }
    compute([prop]) {
      return (0, _object.get)(this, `media.${prop}`);
    }
  }, _descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "media", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});