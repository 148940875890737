define("@embroider/util/services/ensure-registered", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@ember/service", "@ember/application"], function (_exports, _defineProperty2, _service, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class EnsureRegisteredService extends _service.default {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "classNonces", new WeakMap());
      (0, _defineProperty2.default)(this, "nonceCounter", 0);
    }
    register(klass, owner = (0, _application.getOwner)(this)) {
      let nonce = this.classNonces.get(klass);
      if (nonce == null) {
        nonce = `-ensure${this.nonceCounter++}`;
        this.classNonces.set(klass, nonce);
        owner.register(`component:${nonce}`, klass);
      }
      return nonce;
    }
  }
  _exports.default = EnsureRegisteredService;
});