define("ember-cli-qrcode/components/qr-code", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/template", "ember-cli-qrcode/system/qr-code"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _templateFactory, _component2, _tracking, _template, _qrCode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <svg
    width={{this.size}}
    height={{this.size}}
    viewBox={{this.viewBox}}
    xmlns:xlink="http://www.w3.org/1999/xlink"
    ...attributes
  >
    <rect fill={{this.lightColor}} width="100%" height="100%"></rect>
    <rect
      fill={{this.darkColor}}
      width="1"
      height="1"
      id="template-{{this.elementId}}"
    ></rect>
  
    {{#each this.rows as |cols y|}}
      {{#each cols as |visible x|}}
        {{#if visible}}
          <use x={{x}} y={{y}} xlink:href="#template-{{this.elementId}}"></use>
        {{/if}}
      {{/each}}
    {{/each}}
  </svg>
  */
  {
    "id": "efEbJUGH",
    "block": "[[[11,\"svg\"],[16,\"width\",[30,0,[\"size\"]]],[16,\"height\",[30,0,[\"size\"]]],[16,\"viewBox\",[30,0,[\"viewBox\"]]],[24,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[17,1],[12],[1,\"\\n  \"],[10,\"rect\"],[15,\"fill\",[30,0,[\"lightColor\"]]],[14,\"width\",\"100%\"],[14,\"height\",\"100%\"],[12],[13],[1,\"\\n  \"],[10,\"rect\"],[15,\"fill\",[30,0,[\"darkColor\"]]],[14,\"width\",\"1\"],[14,\"height\",\"1\"],[15,1,[29,[\"template-\",[30,0,[\"elementId\"]]]]],[12],[13],[1,\"\\n\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"rows\"]]],null]],null],null,[[[42,[28,[37,1],[[28,[37,1],[[30,2]],null]],null],null,[[[41,[30,4],[[[1,\"        \"],[10,\"use\"],[15,\"x\",[30,5]],[15,\"y\",[30,3]],[15,\"xlink:href\",[29,[\"#template-\",[30,0,[\"elementId\"]]]],\"http://www.w3.org/1999/xlink\"],[12],[13],[1,\"\\n\"]],[]],null]],[4,5]],null]],[2,3]],null],[13]],[\"&attrs\",\"cols\",\"y\",\"visible\",\"x\"],false,[\"each\",\"-track-array\",\"if\"]]",
    "moduleName": "ember-cli-qrcode/components/qr-code.hbs",
    "isStrictMode": false
  });
  let QRCode = _exports.default = (_class = class QRCode extends _component2.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "text", _descriptor, this);
      this.size = this.args.size || (0, _template.htmlSafe)("100%");
      this.darkColor = this.args.darkColor || "#000000";
      this.lightColor = this.args.lightColor || "#FFFFFF";
    }
    get code() {
      if (!this.args.text) {
        throw new Error("Must pass @text argument");
      }
      return (0, _qrCode.default)(this.args.text);
    }
    get viewBox() {
      const nCount = this.code.getModuleCount();
      return (0, _template.htmlSafe)(`0 0 ${nCount} ${nCount}`);
    }
    get rows() {
      const {
        code
      } = this;
      const nCount = code.getModuleCount();
      return [...Array(nCount)].map((_, row) => {
        return [...Array(nCount)].map((_, col) => {
          return code.isDark(row, col);
        });
      });
    }
  }, _descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "text", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, QRCode);
});