define('@fortawesome/pro-regular-svg-icons', ['exports'], (function (exports) { 'use strict';

  const faTrashCan = {
    prefix: 'far',
    iconName: 'trash-can',
    icon: [448, 512, [61460, "trash-alt"], "f2ed", "M170.5 51.6L151.5 80l145 0-19-28.4c-1.5-2.2-4-3.6-6.7-3.6l-93.7 0c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80 368 80l48 0 8 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-8 0 0 304c0 44.2-35.8 80-80 80l-224 0c-44.2 0-80-35.8-80-80l0-304-8 0c-13.3 0-24-10.7-24-24S10.7 80 24 80l8 0 48 0 13.8 0 36.7-55.1C140.9 9.4 158.4 0 177.1 0l93.7 0c18.7 0 36.2 9.4 46.6 24.9zM80 128l0 304c0 17.7 14.3 32 32 32l224 0c17.7 0 32-14.3 32-32l0-304L80 128zm80 64l0 208c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-208c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0l0 208c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-208c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0l0 208c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-208c0-8.8 7.2-16 16-16s16 7.2 16 16z"]
  };
  const faTrashAlt = faTrashCan;
  const faCalendarDays = {
    prefix: 'far',
    iconName: 'calendar-days',
    icon: [448, 512, ["calendar-alt"], "f073", "M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40L64 64C28.7 64 0 92.7 0 128l0 16 0 48L0 448c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-256 0-48 0-16c0-35.3-28.7-64-64-64l-40 0 0-40c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40L152 64l0-40zM48 192l80 0 0 56-80 0 0-56zm0 104l80 0 0 64-80 0 0-64zm128 0l96 0 0 64-96 0 0-64zm144 0l80 0 0 64-80 0 0-64zm80-48l-80 0 0-56 80 0 0 56zm0 160l0 40c0 8.8-7.2 16-16 16l-64 0 0-56 80 0zm-128 0l0 56-96 0 0-56 96 0zm-144 0l0 56-64 0c-8.8 0-16-7.2-16-16l0-40 80 0zM272 248l-96 0 0-56 96 0 0 56z"]
  };
  const faCalendarAlt = faCalendarDays;
  const faPencil = {
    prefix: 'far',
    iconName: 'pencil',
    icon: [512, 512, [9999, 61504, "pencil-alt"], "f303", "M36.4 360.9L13.4 439 1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1L73 498.6l78.1-23c10.4-3 20.1-8 28.6-14.5l.3 .2 .5-.8c1.4-1.1 2.7-2.2 4-3.3c1.4-1.2 2.7-2.5 4-3.8L492.7 149.3c21.9-21.9 24.6-55.6 8.2-80.5c-2.3-3.5-5.1-6.9-8.2-10L453.3 19.3c-25-25-65.5-25-90.5 0L58.6 323.5c-2.5 2.5-4.9 5.2-7.1 8l-.8 .5 .2 .3c-6.5 8.5-11.4 18.2-14.5 28.6zM383 191L197.4 376.6l-49.6-12.4-12.4-49.6L321 129 383 191zM97 358.9l7.7 31c2.1 8.6 8.9 15.3 17.5 17.5l31 7.7-7.4 11.2c-2.6 1.4-5.3 2.6-8.1 3.4l-23.4 6.9L59.4 452.6l16.1-54.8 6.9-23.4c.8-2.8 2-5.6 3.4-8.1L97 358.9zM315.3 218.7c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0l-96 96c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0l96-96z"]
  };
  const faUserNinja = {
    prefix: 'far',
    iconName: 'user-ninja',
    icon: [448, 512, [129399], "f504", "M352 128c0 70.7-57.3 128-128 128c-57.2 0-105.6-37.5-122-89.3c-1.1 1.3-2.2 2.6-3.5 3.8c-15.8 15.8-38.8 20.7-53.6 22.1c-8.1 .8-14.6-5.7-13.8-13.8c1.4-14.7 6.3-37.8 22.1-53.6c5.8-5.8 12.6-10.1 19.6-13.4c-7-3.2-13.8-7.6-19.6-13.4C37.4 82.7 32.6 59.7 31.1 44.9c-.8-8.1 5.7-14.6 13.8-13.8c14.7 1.4 37.8 6.3 53.6 22.1c4.8 4.8 8.7 10.4 11.7 16.1C131.4 28.2 174.4 0 224 0c70.7 0 128 57.3 128 128zM144 112c0 8.8 7.2 16 16 16l128 0c8.8 0 16-7.2 16-16s-7.2-16-16-16L160 96c-8.8 0-16 7.2-16 16zM49.3 464l163.1 0-6.5-7.7c-.2-.2-.4-.4-.6-.7l-78.4-93.1C85.9 380.1 55.7 418.3 49.3 464zm206.1-23.5L275.2 464l123.6 0c-6.4-45.7-36.6-83.9-77.7-101.5l-65.7 78zM418.3 512l-154.1 0-.3 0L29.7 512C13.3 512 0 498.7 0 482.3c0-83.7 57.6-153.9 135.4-173.1c5.6-1.4 11.4 .7 15.1 5.1l61.3 71.6c6.4 7.4 17.9 7.4 24.3 0l61.3-71.6c3.7-4.4 9.5-6.4 15.1-5.1C390.4 328.4 448 398.6 448 482.3c0 16.4-13.3 29.7-29.7 29.7z"]
  };
  const faElephant = {
    prefix: 'far',
    iconName: 'elephant',
    icon: [640, 512, [128024], "f6da", "M299.8 48c-33 0-59.8 26.8-59.8 59.8l0 4.2c0 35.3 28.7 64 64 64l8 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-8 0c-61.9 0-112-50.1-112-112l0-4.2c0-6.4 .6-12.7 1.6-18.9C127.5 111.6 80 174.2 80 248l0 19c0 15.5-1.5 31-4.6 46.3c-3.3 16.3-10.1 31.7-20.1 44.9L43.2 374.4c-8 10.6-23 12.8-33.6 4.8s-12.8-23-4.8-33.6l12.1-16.2c5.7-7.6 9.6-16.3 11.4-25.6C30.8 291.7 32 279.4 32 267l0-19C32 137.6 114.8 46.6 221.6 33.6C241.3 12.9 269.1 0 299.8 0c30.8 0 59 12.1 79.8 32l4.4 0c88.4 0 160 71.6 160 160l0 104c0 13.3-10.7 24-24 24s-24-10.7-24-24l0-104c0-52.6-36.3-96.8-85.3-108.8c2.1 7.3 3.5 14.9 4.2 22.7l1 12.1c1.1 13.2-8.7 24.8-21.9 25.9s-24.8-8.7-25.9-21.9l-1-12.1c-2.9-35-32.1-61.9-67.2-61.9zM528 381l0 18.7c0 17.8 14.5 32.3 32.3 32.3c15.8 0 29.3-11.4 31.9-27l.2-1c2.2-13.1 14.5-21.9 27.6-19.7s21.9 14.5 19.7 27.6l-.2 1c-6.5 38.7-40 67.1-79.2 67.1C516 480 480 444 480 399.7l0-18.9c-24-4.1-45.9-13.9-64-28.1L416 464c0 26.5-21.5 48-48 48l-48 0c-26.5 0-48-21.5-48-48l0-52.3c-21.2 2.8-42.8 2.8-64 0l0 52.3c0 26.5-21.5 48-48 48l-48 0c-26.5 0-48-21.5-48-48l0-72c0-13.3 10.7-24 24-24s24 10.7 24 24l0 72 48 0 0-80c0-7 3-13.6 8.3-18.1s12.2-6.6 19.1-5.6l24.3 3.5c18.8 2.7 37.8 2.7 56.6 0l24.3-3.5c6.9-1 13.9 1.1 19.1 5.6S320 377 320 384l0 80 48 0 0-152c0-13.3 10.7-24 24-24c11.2 0 20.6 7.6 23.2 18c18.7 26.7 51.6 44.9 89.5 44.9c30.1 0 57.1-11.4 76.3-29.6c6.4-6.1 16.5-5.8 22.6 .6s5.8 16.5-.6 22.6c-19.9 18.9-46 31.9-75 36.5zM432 192a16 16 0 1 1 0 32 16 16 0 1 1 0-32z"]
  };
  const faReply = {
    prefix: 'far',
    iconName: 'reply',
    icon: [512, 512, [61714, "mail-reply"], "f3e5", "M224 240l96 0c66.2 0 122 44.7 138.8 105.5c3.3-12.4 5.2-26.2 5.2-41.5c0-70.7-57.3-128-128-128l-112 0-24 0c-13.3 0-24-10.7-24-24l0-24 0-28.1L55.9 208 176 316.1l0-28.1 0-24c0-13.3 10.7-24 24-24l24 0zm0 48l0 48 0 16c0 12.6-7.4 24.1-19 29.2s-25 3-34.4-5.4l-160-144C3.9 225.7 0 217.1 0 208s3.9-17.7 10.6-23.8l160-144c9.4-8.5 22.9-10.6 34.4-5.4s19 16.6 19 29.2l0 16 0 48 48 0 64 0c97.2 0 176 78.8 176 176c0 78-38.6 126.2-68.7 152.1c-4.1 3.5-8.1 6.6-11.7 9.3c-3.2 2.4-6.2 4.4-8.9 6.2c-4.5 3-8.3 5.1-10.8 6.5c-2.5 1.4-5.3 1.9-8.1 1.9c-10.9 0-19.7-8.9-19.7-19.7c0-6.8 3.6-13.2 8.3-18.1c.5-.5 .9-.9 1.4-1.4c2.4-2.3 5.1-5.1 7.7-8.6c1.7-2.3 3.4-5 5-7.9c5.3-9.7 9.5-22.9 9.5-40.2c0-53-43-96-96-96l-48 0-48 0z"]
  };
  const faHeartCrack = {
    prefix: 'far',
    iconName: 'heart-crack',
    icon: [512, 512, [128148, "heart-broken"], "f7a9", "M223.3 465.9l2.5 2.3c8.2 7.6 19 11.9 30.2 11.9s22-4.2 30.2-11.9l2.5-2.3L463.9 303.2c30.7-28.5 48.1-68.5 48.1-110.4l0-3.3c0-70.4-50-130.8-119.2-144C353.4 37.9 313.1 47 281 69.6c0 0 0 0 0 0c-4 2.8-7.8 5.8-11.5 9c-4.7 4.1-9.3 8.5-13.5 13.3c-7.5-8.4-15.9-15.9-25-22.3C198.9 47 158.6 37.9 119.2 45.4C50 58.6 0 119.1 0 189.5l0 3.3c0 41.9 17.4 81.9 48.1 110.4L223.3 465.9zm6.6-331.1l-67.6 67.6c-1.5 1.5-2.4 3.6-2.3 5.8s1 4.2 2.6 5.7l112 104c2.9 2.7 7.4 2.9 10.5 .3s3.8-7 1.7-10.4l-59.7-97L316 158.9c3.6-2.1 5-6.6 3.2-10.3l-16.6-35.1c22.4-18.5 52.2-26.4 81.3-20.9c46.5 8.9 80.1 49.5 80.1 96.9l0 3.3c0 28.5-11.9 55.8-32.8 75.2L256 430.7 80.8 268C59.9 248.6 48 221.3 48 192.8l0-3.3c0-47.3 33.6-88 80.1-96.9c34-6.5 69 5.4 92 31.2c0 0 0 0 0 0l.1 .1 9.6 10.8z"]
  };
  const faHeartBroken = faHeartCrack;
  const faArrowRightFromBracket = {
    prefix: 'far',
    iconName: 'arrow-right-from-bracket',
    icon: [512, 512, ["sign-out"], "f08b", "M505 273c9.4-9.4 9.4-24.6 0-33.9L377 111c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l87 87L184 232c-13.3 0-24 10.7-24 24s10.7 24 24 24l246.1 0-87 87c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0L505 273zM168 80c13.3 0 24-10.7 24-24s-10.7-24-24-24L88 32C39.4 32 0 71.4 0 120L0 392c0 48.6 39.4 88 88 88l80 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-80 0c-22.1 0-40-17.9-40-40l0-272c0-22.1 17.9-40 40-40l80 0z"]
  };
  const faSignOut = faArrowRightFromBracket;
  const faSquirrel = {
    prefix: 'far',
    iconName: 'squirrel',
    icon: [512, 512, [], "f71a", "M147.7 48C92.7 48 48 92.7 48 147.7s44.7 99.7 99.7 99.7c22.4 0 40 19.2 38 41.5l-.7 7.7c-1 11-5.9 20.4-12 27.3c-13.1 14.9-21.1 34.4-21.1 55.8c0 39.1 26.6 72 62.8 81.5c-14.3-20-22.8-44.4-22.8-70.9c0-25 7.7-49.3 22-69.8l35-49.9C284.8 219.5 304 158.5 304 96l0-20.8C304 69 309 64 315.2 64c15.2 0 28.7 7.6 36.8 19.2C360.1 71.6 373.6 64 388.8 64C395 64 400 69 400 75.2L400 96l7.6 0c53.4 0 98.2 40.5 103.5 93.7l.8 8c.5 4.5-.4 9.1-2.4 13.1l-4.1 8.2c-14.4 28.8-42 48.1-73.4 52.3l0 54.8c24.3 19 40 48.7 40 82c0 20.6-6 39.8-16.3 56l33.4 0c13.3 0 24 10.7 24 24s-10.7 24-24 24L368 512l-16 0-38.3 0-77.4 0c-73 0-132.3-59.2-132.3-132.3c0-32.3 11.6-61.9 30.8-84.8C59.3 288.3 0 225 0 147.7C0 66.1 66.1 0 147.7 0C207.3 0 258.6 35.3 282 86c5.5 12 .3 26.3-11.8 31.8s-26.3 .3-31.8-11.8c-15.8-34.3-50.5-58-90.6-58zM352 464l16 0c30.9 0 56-25.1 56-56s-25.1-56-56-56c-5.9 0-11.5 .9-16.8 2.6c-12.6 4-26.1-3.1-30.1-15.7s3.1-26.1 15.7-30.1c9.9-3.1 20.4-4.8 31.2-4.8c5.4 0 10.8 .4 16 1.2l0-57.2c0-13.3 10.7-24 24-24l11.5 0c18.2 0 34.8-10.3 42.9-26.5l1-2-.1-1.1c-2.9-28.6-27-50.4-55.7-50.4l-7.6 0-48 0-3.3 0c-7.6 55.2-28.2 108.1-60.4 154.2l-35 49.9c-8.7 12.4-13.3 27.1-13.3 42.2c0 40.7 33 73.7 73.7 73.7l38.3 0zm32-288a16 16 0 1 1 32 0 16 16 0 1 1 -32 0z"]
  };
  const faFlag = {
    prefix: 'far',
    iconName: 'flag',
    icon: [448, 512, [127988, 61725], "f024", "M48 24C48 10.7 37.3 0 24 0S0 10.7 0 24L0 64 0 350.5 0 400l0 88c0 13.3 10.7 24 24 24s24-10.7 24-24l0-100 80.3-20.1c41.1-10.3 84.6-5.5 122.5 13.4c44.2 22.1 95.5 24.8 141.7 7.4l34.7-13c12.5-4.7 20.8-16.6 20.8-30l0-279.7c0-23-24.2-38-44.8-27.7l-9.6 4.8c-46.3 23.2-100.8 23.2-147.1 0c-35.1-17.6-75.4-22-113.5-12.5L48 52l0-28zm0 77.5l96.6-24.2c27-6.7 55.5-3.6 80.4 8.8c54.9 27.4 118.7 29.7 175 6.8l0 241.8-24.4 9.1c-33.7 12.6-71.2 10.7-103.4-5.4c-48.2-24.1-103.3-30.1-155.6-17.1L48 338.5l0-237z"]
  };
  const faCompress = {
    prefix: 'far',
    iconName: 'compress',
    icon: [448, 512, [], "f066", "M160 56c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 88-88 0c-13.3 0-24 10.7-24 24s10.7 24 24 24l112 0c13.3 0 24-10.7 24-24l0-112zM24 320c-13.3 0-24 10.7-24 24s10.7 24 24 24l88 0 0 88c0 13.3 10.7 24 24 24s24-10.7 24-24l0-112c0-13.3-10.7-24-24-24L24 320zM336 56c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 112c0 13.3 10.7 24 24 24l112 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-88 0 0-88zM312 320c-13.3 0-24 10.7-24 24l0 112c0 13.3 10.7 24 24 24s24-10.7 24-24l0-88 88 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-112 0z"]
  };
  const faBorderCenterV = {
    prefix: 'far',
    iconName: 'border-center-v',
    icon: [448, 512, [], "f89d", "M448 448a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm0-96a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM64 352A32 32 0 1 0 0 352a32 32 0 1 0 64 0zM384 160a32 32 0 1 0 64 0 32 32 0 1 0 -64 0zM64 160A32 32 0 1 0 0 160a32 32 0 1 0 64 0zm320 96a32 32 0 1 0 64 0 32 32 0 1 0 -64 0zM64 256A32 32 0 1 0 0 256a32 32 0 1 0 64 0zM384 64a32 32 0 1 0 64 0 32 32 0 1 0 -64 0zM0 64a32 32 0 1 0 64 0A32 32 0 1 0 0 64zM64 448A32 32 0 1 0 0 448a32 32 0 1 0 64 0zM288 256a32 32 0 1 0 64 0 32 32 0 1 0 -64 0zM352 64a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM288 448a32 32 0 1 0 64 0 32 32 0 1 0 -64 0zM160 64A32 32 0 1 0 96 64a32 32 0 1 0 64 0zM96 448a32 32 0 1 0 64 0 32 32 0 1 0 -64 0zm64-192a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm40 200c0 13.3 10.7 24 24 24s24-10.7 24-24l0-400c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 400z"]
  };
  const faMonkey = {
    prefix: 'far',
    iconName: 'monkey',
    icon: [576, 512, [128018], "f6fb", "M160 416c0-91.5 54.8-170.2 133.5-204.9c7.3 15.1 17.2 28.6 29.3 39.9C255.7 275.8 208 340.4 208 416l0 48 192 0 0-24c0-39.8-32.2-72-72-72l-16 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l16 0c27 0 51.9 8.9 72 24l0-24 0-32.9c5.2 .6 10.6 .9 16 .9c11 0 21.7-1.3 32-3.8l0 1.8 83.5 83.5C560 398 576 436.7 576 477l0 11c0 13.3-10.7 24-24 24s-24-10.7-24-24l0-11c0-27.6-11-54-30.5-73.5L448 353.9l0 86.1 0 24 8 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-8 0-48 0-192 0-48 0-8 0C68.1 512 0 443.9 0 360L0 120C0 53.7 53.7 0 120 0l8 0c53 0 96 43 96 96s-43 96-96 96l-8 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l8 0c26.5 0 48-21.5 48-48s-21.5-48-48-48l-8 0c-39.8 0-72 32.2-72 72l0 240c0 57.4 46.6 104 104 104l8 0 0-48zM448 251c-10.1 3.3-20.8 5-32 5c-5.4 0-10.8-.4-16-1.2c-13.5-2.1-26.1-6.8-37.4-13.5c-16.4-9.8-29.9-24.1-38.8-41.1c-1.4-2.7-2.7-5.4-3.8-8.2c-35.3 0-64-28.7-64-64s28.6-64 64-64c15.7-37.6 52.8-64 96-64s80.4 26.4 96 64c35.3 0 64 28.7 64 64s-28.6 64-64 64c-11.6 27.9-35 49.6-64 59zM384 64c-26.5 0-48 21.5-48 48c0 20.8 13.2 38.4 31.6 45.1c4.7 1.7 8.4 5.9 8.4 10.9c0 22.1 17.9 40 40 40s40-17.9 40-40c0-5 3.7-9.2 8.4-10.9c18.5-6.7 31.6-24.4 31.6-45.1c0-26.5-21.5-48-48-48l-64 0zm0 32a16 16 0 1 1 0 32 16 16 0 1 1 0-32zm48 16a16 16 0 1 1 32 0 16 16 0 1 1 -32 0z"]
  };
  const faChevronUp = {
    prefix: 'far',
    iconName: 'chevron-up',
    icon: [512, 512, [], "f077", "M239 111c9.4-9.4 24.6-9.4 33.9 0L465 303c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-175-175L81 337c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9L239 111z"]
  };
  const faFilePlus = {
    prefix: 'far',
    iconName: 'file-plus',
    icon: [384, 512, [], "f319", "M48 448L48 64c0-8.8 7.2-16 16-16l160 0 0 80c0 17.7 14.3 32 32 32l80 0 0 288c0 8.8-7.2 16-16 16L64 464c-8.8 0-16-7.2-16-16zM64 0C28.7 0 0 28.7 0 64L0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-293.5c0-17-6.7-33.3-18.7-45.3L274.7 18.7C262.7 6.7 246.5 0 229.5 0L64 0zM192 208c-13.3 0-24 10.7-24 24l0 48-48 0c-13.3 0-24 10.7-24 24s10.7 24 24 24l48 0 0 48c0 13.3 10.7 24 24 24s24-10.7 24-24l0-48 48 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-48 0 0-48c0-13.3-10.7-24-24-24z"]
  };
  const faShieldCheck = {
    prefix: 'far',
    iconName: 'shield-check',
    icon: [512, 512, [], "f2f7", "M73 127L256 49.4 439 127c5.9 2.5 9.1 7.8 9 12.8c-.4 91.4-38.4 249.3-186.3 320.1c-3.6 1.7-7.8 1.7-11.3 0C102.4 389 64.5 231.2 64 139.7c0-5 3.1-10.2 9-12.8zM457.7 82.8L269.4 2.9C265.2 1 260.7 0 256 0s-9.2 1-13.4 2.9L54.3 82.8c-22 9.3-38.4 31-38.3 57.2c.5 99.2 41.3 280.7 213.6 363.2c16.7 8 36.1 8 52.8 0C454.8 420.7 495.5 239.2 496 140c.1-26.2-16.3-47.9-38.3-57.2zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"]
  };
  const faWavePulse = {
    prefix: 'far',
    iconName: 'wave-pulse',
    icon: [640, 512, ["heart-rate"], "f5f8", "M319.4 0c11.1-.3 21 7.1 23.8 17.9l79.3 303.9 27.1-57.7c9.2-19.6 29-32.2 50.7-32.2L616 232c13.3 0 24 10.7 24 24s-10.7 24-24 24l-115.7 0c-3.1 0-5.9 1.8-7.2 4.6L437.7 402.2c-4.3 9.1-13.9 14.6-23.9 13.7s-18.5-8.1-21-17.8L322.7 129.4 247.5 492.9c-2.2 10.9-11.6 18.8-22.7 19.1s-21-6.9-23.9-17.6L143.3 285.9c-1-3.5-4.1-5.9-7.7-5.9L24 280c-13.3 0-24-10.7-24-24s10.7-24 24-24l111.6 0c25.2 0 47.3 16.8 54 41.1l31 112.6L296.5 19.1C298.8 8.2 308.2 .3 319.4 0z"]
  };
  const faHeartRate = faWavePulse;
  const faKey = {
    prefix: 'far',
    iconName: 'key',
    icon: [512, 512, [128273], "f084", "M208 176c0-70.7 57.3-128 128-128s128 57.3 128 128s-57.3 128-128 128c-10.4 0-20.5-1.2-30.1-3.6c-8.1-2-16.7 .4-22.6 6.4L254.1 336 200 336c-13.3 0-24 10.7-24 24l0 40-40 0c-13.3 0-24 10.7-24 24l0 40-64 0 0-78.1L205.2 228.7c5.9-5.9 8.3-14.5 6.4-22.6c-2.3-9.6-3.6-19.7-3.6-30.1zM336 0C238.8 0 160 78.8 160 176c0 9.5 .7 18.8 2.2 27.9L7 359c-4.5 4.5-7 10.6-7 17L0 488c0 13.3 10.7 24 24 24l112 0c13.3 0 24-10.7 24-24l0-40 40 0c13.3 0 24-10.7 24-24l0-40 40 0c6.4 0 12.5-2.5 17-7l27.2-27.2c9.1 1.4 18.4 2.2 27.9 2.2c97.2 0 176-78.8 176-176S433.2 0 336 0zm32 176a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"]
  };
  const faPegasus = {
    prefix: 'far',
    iconName: 'pegasus',
    icon: [576, 512, [], "f703", "M432 0c-1.8 0-3.5 .2-5.2 .6C366.3 5.8 316 46.3 296.6 101.5L162.7 34.5c-7.4-3.7-16.3-3.3-23.4 1.1S128 47.7 128 56c0 81 33.4 133.2 67.7 164.8c16.9 15.6 33.8 26 46.6 32.5c6.4 3.3 11.8 5.6 15.7 7.2c2 .8 3.6 1.4 4.7 1.8c.5 .2 1 .3 1.3 .5c2.5 .9 5.1 1.3 7.9 1.3c13.3 0 24-10.7 24-24c0-10.8-7.2-20-17-23l-.4-.1c-.6-.2-1.6-.6-2.9-1.1c-2.6-1-6.6-2.8-11.5-5.2c-9.8-5-22.9-13.1-35.9-25.1c-19.9-18.3-40.3-46.4-48.5-88.8l121.5 60.8c3.6 1.8 7.4 2.6 11.2 2.5l.5 0c12.1 0 22.3-9 23.8-21c6.1-49.1 46.6-87.7 96.6-90.8c.7 0 1.3-.1 2-.2l4.6 0 24 0 8 0 .5 0c19.9 0 36.8 14.6 39.6 34.3l9.9 69.2c2.1 14.8-6.2 29.1-20 34.7c-13.3 5.3-28.4 1.3-37.3-9.8l-5.9-7.3c-6.4-8-17.1-11-26.7-7.7s-16.1 12.5-16.1 22.6l0 55c0 .5 0 1 0 1.5c0 .6 0 1.2 0 1.7c0 23.5-9.6 44.1-24.7 58.8c-4.6 4.5-7.3 10.7-7.3 17.2L384 464l-48 0 0-117.8c0-11.3-7.9-21.1-19-23.5c-2.1-.4-4.2-1-6.3-1.6l-88-25.7c-7.3-2.1-15.3-.7-21.3 4s-9.6 11.9-9.4 19.5c0 .4 0 .9 0 1.3c0 8-1.4 16-4.3 23.5L175.6 376c-4.2 11.1-4.7 23.2-1.5 34.6L188.9 464l-49.8 0-11.2-40.5c-5.9-21.2-4.9-43.7 2.8-64.4l8.2-21.7c4.2-11.2 4.1-23.6-.4-34.7l-21.9-54.6c-3-7.6-4.6-15.7-4.6-23.9c0-15.5 5.5-29.7 14.6-40.8c-7.7-14.2-14.5-30.1-19.6-47.6c-8.8 6.9-16.5 15-22.9 24.2C37.3 162.1 0 200.7 0 248l0 64c0 13.3 10.7 24 24 24s24-10.7 24-24l0-64c0-13.2 6.4-25 16.3-32.2c-.2 2.8-.3 5.6-.3 8.5c0 14.3 2.7 28.4 8 41.7l21.9 54.6-8.2 21.7c-11.3 30.1-12.7 63.1-4.1 94.1l11.2 40.5c5.8 20.8 24.7 35.2 46.2 35.2l49.8 0c31.7 0 54.7-30.3 46.2-60.8l-14.8-53.3c-.5-1.6-.4-3.4 .2-5l12.1-32.3c1.4-3.7 2.6-7.4 3.6-11.2L288 364.5l0 99.5c0 26.5 21.5 48 48 48l48 0c26.5 0 48-21.5 48-48l0-136.2c19.8-22.6 32-52.3 32-85.5l0-1.6 0-.7 0-7.8c17.7 6 37.5 5.8 55.8-1.5c34.4-13.8 55-49.3 49.7-86l-9.9-69.2c-1.6-11.3-5.3-21.9-10.7-31.3C555.6 40 560 32.5 560 24c0-13.3-10.7-24-24-24L472.5 0 472 0l-8 0L440 0l-8 0zm48 96a16 16 0 1 0 -32 0 16 16 0 1 0 32 0z"]
  };
  const faRightToBracket = {
    prefix: 'far',
    iconName: 'right-to-bracket',
    icon: [512, 512, ["sign-in-alt"], "f2f6", "M192 365.8L302 256 192 146.2l0 53.8c0 13.3-10.7 24-24 24L48 224l0 64 120 0c13.3 0 24 10.7 24 24l0 53.8zM352 256c0 11.5-4.6 22.5-12.7 30.6L223.2 402.4c-8.7 8.7-20.5 13.6-32.8 13.6c-25.6 0-46.4-20.8-46.4-46.4l0-33.6-96 0c-26.5 0-48-21.5-48-48l0-64c0-26.5 21.5-48 48-48l96 0 0-33.6c0-25.6 20.8-46.4 46.4-46.4c12.3 0 24.1 4.9 32.8 13.6L339.3 225.4c8.1 8.1 12.7 19.1 12.7 30.6zm-8 176l80 0c22.1 0 40-17.9 40-40l0-272c0-22.1-17.9-40-40-40l-80 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l80 0c48.6 0 88 39.4 88 88l0 272c0 48.6-39.4 88-88 88l-80 0c-13.3 0-24-10.7-24-24s10.7-24 24-24z"]
  };
  const faSignInAlt = faRightToBracket;
  const faCity = {
    prefix: 'far',
    iconName: 'city',
    icon: [640, 512, [127961], "f64f", "M104 0C90.7 0 80 10.7 80 24l0 40-8 0C32.2 64 0 96.2 0 136L0 440c0 39.8 32.2 72 72 72l168 0c-10-13.4-16-30-16-48L72 464c-13.3 0-24-10.7-24-24l0-304c0-13.3 10.7-24 24-24l8 0 48 0 48 0 48 0 0-48 0-40c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40-48 0 0-40c0-13.3-10.7-24-24-24zM464 160c0 26.5 21.5 48 48 48l64 0c8.8 0 16 7.2 16 16l0 224c0 8.8-7.2 16-16 16l-128 0-128 0c-8.8 0-16-7.2-16-16l0-224 0-160c0-8.8 7.2-16 16-16l128 0c8.8 0 16 7.2 16 16l0 96zm48-48l0-48c0-35.3-28.7-64-64-64L320 0c-35.3 0-64 28.7-64 64l0 160 0 224c0 35.3 28.7 64 64 64l128 0 128 0c35.3 0 64-28.7 64-64l0-224c0-35.3-28.7-64-64-64l-16 0-48 0 0-48zM352 336c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16l0 32zM368 96c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zM352 240c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16l0 32zM496 416l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16zM480 304c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16l0 32zM112 320l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16zM96 400c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16l0 32zm16-176l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16z"]
  };
  const faBadgerHoney = {
    prefix: 'far',
    iconName: 'badger-honey',
    icon: [640, 512, [129441], "f6b4", "M465 112c16.9 0 33.6 3.7 49 10.9L559.2 144l-72.3 0c-49.3 0-97.7 13.4-139.9 38.8l-69.4 41.7c-17 10.2-36.4 15.6-56.2 15.6c-55.4 0-101.1-41.2-108.3-94.6c4.9-.9 9.9-1.4 15-1.4l204.2 0c27.3 0 54.1-6.3 78.5-18.5l2.5-1.2c16.1-8 33.8-12.2 51.8-12.2zm126.8 64c-.6 4.7-2.8 9-6.1 12.4L583 191l16.4 16.4L583 191c-5.5 5.5-13.8 9.2-23.9 10.1c-67.9 6.4-128.1 37.5-169.4 67.9c-7.8 5.7-11.4 15.6-9.1 25c0 .2 .1 .4 .1 .6c0 0 0 0 0 0l21.3 128c.8 4.9-2.9 9.3-7.9 9.3l-31.1 0c-3.9 0-7.2-2.8-7.9-6.7L338.3 324.1c-1.9-11.6-11.9-20.1-23.7-20.1l-110.1 0c-9.6 0-18.3 5.7-22.1 14.5l-16 37.3c-5.2 12.1-5.9 25.6-2.2 38.2l8.3 27.7c1.5 5.1-2.3 10.3-7.7 10.3l-29.2 0c-3.5 0-6.6-2.3-7.7-5.7L115 382.6c-1.3-4.4-1.3-9.1-.1-13.5l10.7-37.6c1.8-6.2 1-13-2.3-18.6s-8.6-9.7-14.9-11.3C73.7 292.9 48 261.4 48 224c0-27.3 13.7-51.5 34.7-65.9C95.4 223 152.6 272 221.3 272c25.6 0 50.7-7 72.7-20.1l69.4-41.7c33.2-19.9 70.6-31.5 109.1-33.8c2 8.9 10 15.6 19.5 15.6c9.7 0 17.7-6.9 19.6-16l80.2 0zM48 323.9c7.8 6.2 16.3 11.6 25.4 15.9l-4.6 16.1c-3.8 13.2-3.7 27.3 .3 40.5l13.1 43.7c7.1 23.7 28.9 39.9 53.6 39.9l29.2 0c37.5 0 64.4-36.2 53.6-72.1l-8.3-27.7c-.5-1.8-.4-3.7 .3-5.4l9.8-22.8 73.9 0 13.5 81.2c4.5 27 27.9 46.8 55.2 46.8l31.1 0c34.6 0 60.9-31.1 55.2-65.2L430.2 299.3c28.6-19.1 64.3-36.8 103.4-45.6l19.6 31.4c3.1 5 10.4 5 13.6 0l27.8-44.5c8.1-3.8 15.7-9 22.4-15.7l2.6-2.6c13.1-13.1 20.4-30.8 20.4-49.3c0-27.1-15.7-51.7-40.2-63.1L534.2 79.4C512.6 69.2 488.9 64 465 64c-25.4 0-50.5 5.9-73.2 17.3l-2.5 1.2C371.6 91.4 352 96 332.2 96L128 96C57.3 96 0 153.3 0 224L0 328c0 13.3 10.7 24 24 24s24-10.7 24-24l0-4.1z"]
  };
  const faSquarePlus = {
    prefix: 'far',
    iconName: 'square-plus',
    icon: [448, 512, [61846, "plus-square"], "f0fe", "M64 80c-8.8 0-16 7.2-16 16l0 320c0 8.8 7.2 16 16 16l320 0c8.8 0 16-7.2 16-16l0-320c0-8.8-7.2-16-16-16L64 80zM0 96C0 60.7 28.7 32 64 32l320 0c35.3 0 64 28.7 64 64l0 320c0 35.3-28.7 64-64 64L64 480c-35.3 0-64-28.7-64-64L0 96zM200 344l0-64-64 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l64 0 0-64c0-13.3 10.7-24 24-24s24 10.7 24 24l0 64 64 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-64 0 0 64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"]
  };
  const faPlusSquare = faSquarePlus;
  const faMessageLines = {
    prefix: 'far',
    iconName: 'message-lines',
    icon: [512, 512, ["comment-alt-lines"], "f4a6", "M208 416c0-26.5-21.5-48-48-48l-96 0c-8.8 0-16-7.2-16-16L48 64c0-8.8 7.2-16 16-16l384 0c8.8 0 16 7.2 16 16l0 288c0 8.8-7.2 16-16 16l-138.7 0c-10.4 0-20.5 3.4-28.8 9.6L208 432l0-16zm-.2 76.2l.2-.2 101.3-76L448 416c35.3 0 64-28.7 64-64l0-288c0-35.3-28.7-64-64-64L64 0C28.7 0 0 28.7 0 64L0 352c0 35.3 28.7 64 64 64l48 0 48 0 0 48 0 4 0 .3 0 6.4 0 21.3c0 6.1 3.4 11.6 8.8 14.3s11.9 2.1 16.8-1.5L202.7 496l5.1-3.8zM152 144c-13.3 0-24 10.7-24 24s10.7 24 24 24l208 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-208 0zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24l112 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-112 0z"]
  };
  const faCommentAltLines = faMessageLines;
  const faArrowLeftFromLine = {
    prefix: 'far',
    iconName: 'arrow-left-from-line',
    icon: [448, 512, [8612, "arrow-from-right"], "f344", "M7.6 238.5C2.7 243.1 0 249.4 0 256s2.7 12.9 7.6 17.5l136 128c9.7 9.1 24.8 8.6 33.9-1s8.6-24.8-1-33.9L84.5 280l83.5 0 128 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-128 0-83.5 0 91.9-86.5c9.7-9.1 10.1-24.3 1-33.9s-24.3-10.1-33.9-1l-136 128zM400 424c0 13.3 10.7 24 24 24s24-10.7 24-24l0-336c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 336z"]
  };
  const faArrowFromRight = faArrowLeftFromLine;
  const faCatSpace = {
    prefix: 'far',
    iconName: 'cat-space',
    icon: [640, 512, [], "e001", "M464 304c70.7 0 128-57.3 128-128c0-32.5-12.1-62.1-32-84.7l0 84.7c0 53-43 96-96 96s-96-43-96-96l0-84.7c-19.9 22.6-32 52.2-32 84.7c0 70.7 57.3 128 128 128zM397.8 66.4l29.4 39.2c3 4 7.8 6.4 12.8 6.4l48 0c5 0 9.8-2.4 12.8-6.4l29.4-39.2C510.9 54.7 488.2 48 464 48s-46.9 6.7-66.2 18.4zM464 352l0 96c0 8.8 7.2 16 16 16s16-7.2 16-16l0-98.9c-10.4 1.9-21.1 2.9-32 2.9zM640 176c0 68.4-39 127.7-96 156.8L544 448c0 35.3-28.7 64-64 64s-64-28.7-64-64l0-54L336 441l0 23 24 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-176 0c-48.6 0-88-39.4-88-88c0 0 0-.1 0-.1l0-239.4c0-20.2-15-37.2-35-39.7l-7.9-1c-13.2-1.6-22.5-13.6-20.8-26.8S45.8 94.5 59 96.2l7.9 1c44 5.5 77.1 42.9 77.1 87.3l0 94.5c34.5-46.4 85.2-80.1 144.3-92.7c-.2-3.4-.3-6.9-.3-10.3C288 78.8 366.8 0 464 0s176 78.8 176 176zM144 423.9c0 0 0 .1 0 .1c0 22.1 17.9 40 40 40l104 0 0-64c0-17.7-14.3-32-32-32l-8 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l8 0c39.4 0 72.2 28.5 78.8 66l73.3-43.1c-51.7-17.3-92.7-58-110.5-109.5C209 252.6 144 331.2 144 423.9zM231.5 12.3l10.7 28.8c.8 2.2 2.5 3.9 4.7 4.7l28.8 10.7c7 2.6 7 12.4 0 15L246.9 82.2c-2.2 .8-3.9 2.5-4.7 4.7l-10.7 28.8c-2.6 7-12.4 7-15 0L205.8 86.9c-.8-2.2-2.5-3.9-4.7-4.7L172.3 71.5c-7-2.6-7-12.4 0-15l28.8-10.7c2.2-.8 3.9-2.5 4.7-4.7l10.7-28.8c2.6-7 12.4-7 15 0zM432 192a16 16 0 1 0 0-32 16 16 0 1 0 0 32zm80-16a16 16 0 1 0 -32 0 16 16 0 1 0 32 0z"]
  };
  const faCode = {
    prefix: 'far',
    iconName: 'code',
    icon: [640, 512, [], "f121", "M399.1 1.1c-12.7-3.9-26.1 3.1-30 15.8l-144 464c-3.9 12.7 3.1 26.1 15.8 30s26.1-3.1 30-15.8l144-464c3.9-12.7-3.1-26.1-15.8-30zm71.4 118.5c-9.1 9.7-8.6 24.9 1.1 33.9L580.9 256 471.6 358.5c-9.7 9.1-10.2 24.3-1.1 33.9s24.3 10.2 33.9 1.1l128-120c4.8-4.5 7.6-10.9 7.6-17.5s-2.7-13-7.6-17.5l-128-120c-9.7-9.1-24.9-8.6-33.9 1.1zm-301 0c-9.1-9.7-24.3-10.2-33.9-1.1l-128 120C2.7 243 0 249.4 0 256s2.7 13 7.6 17.5l128 120c9.7 9.1 24.9 8.6 33.9-1.1s8.6-24.9-1.1-33.9L59.1 256 168.4 153.5c9.7-9.1 10.2-24.3 1.1-33.9z"]
  };
  const faUserCowboy = {
    prefix: 'far',
    iconName: 'user-cowboy',
    icon: [448, 512, [], "f8ea", "M208.5 9.9c4.8 3.3 8.8 6.1 15.5 6.1s10.8-2.8 15.5-6.1C246.1 5.4 254 0 272 0c35.6 0 58.8 59.3 72.4 112c7.1-2.2 13.7-4.5 19.8-6.9c32.4-12.7 49.4-27.2 55-34.7c4.7-6.3 13.3-8.2 20.2-4.6s10.2 11.8 7.8 19.2c-9.3 27.9-35 62.4-72.5 89.9c-7.4 5.4-15.4 10.6-23.8 15.5c.8 5.8 1.2 11.6 1.2 17.6c0 70.7-57.3 128-128 128s-128-57.3-128-128c0-6 .4-11.9 1.2-17.6c-8.4-4.8-16.4-10-23.8-15.5C35.8 147.5 10.1 113 .8 85.1c-2.5-7.4 .8-15.6 7.8-19.2s15.5-1.7 20.2 4.6c5.6 7.5 22.7 22 55 34.7c6.1 2.4 12.7 4.7 19.8 6.9C117.2 59.3 140.4 0 176 0c18 0 25.9 5.4 32.5 9.9zM224 224c-29 0-55.8-4.8-79.9-12.7c1.7 42.7 36.8 76.7 79.9 76.7s78.2-34.1 79.9-76.7C279.8 219.2 253 224 224 224zM54.2 458.7l-.6 5.3 340.7 0-.6-5.3c-2.7-24.3-23.2-42.7-47.7-42.7l-244.2 0c-24.5 0-45 18.4-47.7 42.7zM6.5 453.4C11.9 404.8 53 368 101.9 368l244.2 0c48.9 0 90 36.8 95.4 85.4l2.6 23.1c2.1 19-12.7 35.5-31.8 35.5L35.8 512c-19.1 0-33.9-16.6-31.8-35.5l2.6-23.1z"]
  };
  const faHeart = {
    prefix: 'far',
    iconName: 'heart',
    icon: [512, 512, [128153, 128154, 128155, 128156, 128420, 129293, 129294, 129505, 9829, 10084, 61578], "f004", "M225.8 468.2l-2.5-2.3L48.1 303.2C17.4 274.7 0 234.7 0 192.8l0-3.3c0-70.4 50-130.8 119.2-144C158.6 37.9 198.9 47 231 69.6c9 6.4 17.4 13.8 25 22.3c4.2-4.8 8.7-9.2 13.5-13.3c3.7-3.2 7.5-6.2 11.5-9c0 0 0 0 0 0C313.1 47 353.4 37.9 392.8 45.4C462 58.6 512 119.1 512 189.5l0 3.3c0 41.9-17.4 81.9-48.1 110.4L288.7 465.9l-2.5 2.3c-8.2 7.6-19 11.9-30.2 11.9s-22-4.2-30.2-11.9zM239.1 145c-.4-.3-.7-.7-1-1.1l-17.8-20-.1-.1s0 0 0 0c-23.1-25.9-58-37.7-92-31.2C81.6 101.5 48 142.1 48 189.5l0 3.3c0 28.5 11.9 55.8 32.8 75.2L256 430.7 431.2 268c20.9-19.4 32.8-46.7 32.8-75.2l0-3.3c0-47.3-33.6-88-80.1-96.9c-34-6.5-69 5.4-92 31.2c0 0 0 0-.1 .1s0 0-.1 .1l-17.8 20c-.3 .4-.7 .7-1 1.1c-4.5 4.5-10.6 7-16.9 7s-12.4-2.5-16.9-7z"]
  };
  const faRabbit = {
    prefix: 'far',
    iconName: 'rabbit',
    icon: [512, 512, [128007], "f708", "M245.7 0c-24.4 0-45.1 17.9-48.5 42.1l-.7 4.9c-7.8 54.3 8.4 108.9 43.5 150.1l0 20.6c-66.8 20.7-119.8 73-141.4 139.4C90.3 353.8 81.4 352 72 352c-39.8 0-72 32.2-72 72s32.2 72 72 72c14.7 0 28.3-4.4 39.7-11.9C127.8 501.3 150.6 512 176 512l32 0 96 0c23.9 0 44.7-13.1 55.7-32.4l5.1 6.8c21.2 28.3 61.3 34 89.6 12.8s34-61.3 12.8-89.6L388 304c46.4-.1 84-37.8 84-84.3c0-25.3-11.4-49.3-31-65.3L404 124.3c-5.6-4.5-11.5-8.6-17.6-12c10.1-24.8 13.6-52.1 9.8-79.4l-.9-6C393.1 11.4 379.9 0 364.3 0c-8.2 0-16.2 3.3-22 9.1l-2 2c-5.8 5.7-11 11.9-15.7 18.3c14.7 21.5 25.1 45.4 30.7 70.4c-8.4-2.1-17-3.4-25.7-3.7c-8.2-29.3-23.8-56.6-46.2-78.8l-3.1-3.1L263.4 31.1l16.8-16.9C271 5.1 258.6 0 245.7 0zM88.7 406.7c-.4 5.7-.7 11.4-.7 17.3c0 5.6 .5 11.1 1.5 16.4C85.1 445.1 78.9 448 72 448c-13.3 0-24-10.7-24-24s10.7-24 24-24c6.5 0 12.4 2.6 16.7 6.7zm156-357.9c.1-.5 .5-.9 1-.9c.3 0 .5 .1 .7 .3l3.1 3.1 16.8-16.9L249.5 51.4c20.4 20.2 33.2 46.2 37.2 73.7c1 6.7 4.7 12.6 10.3 16.4s12.5 5 19 3.5c2.7-.6 5.5-1 8.5-1c17.9 0 35.3 6.2 49.3 17.5l36.9 30.1c8.4 6.9 13.4 17.2 13.4 28.1c0 20-16.2 36.3-36.3 36.3L352 256c-13.3 0-24 10.7-24 24l0 16c0 5.2 1.7 10.2 4.8 14.4l96 128c5.3 7.1 3.9 17.1-3.2 22.4s-17.1 3.9-22.4-3.2l-68.6-91.4c-4.2-5.6-10.6-9-17.5-9.5s-13.8 2.1-18.7 7L271 391c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8l16 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-96 0-32 0c-22.1 0-40-17.9-40-40c0-80.8 57-148.2 133-164.3c11.1-2.4 19-12.1 19-23.5l0-48.3c0-6.1-2.4-12.1-6.6-16.5C251.8 140.1 237.8 96.8 244 53.8l.7-4.9zM352 208a16 16 0 1 0 0-32 16 16 0 1 0 0 32z"]
  };
  const faUserAstronaut = {
    prefix: 'far',
    iconName: 'user-astronaut',
    icon: [448, 512, [], "f4fb", "M224 48a144 144 0 1 1 0 288 144 144 0 1 1 0-288zm181.2 80.4C379 53.6 307.8 0 224 0S69 53.6 42.8 128.4C27.6 130.9 16 144.1 16 160l0 64c0 15.9 11.6 29.1 26.8 31.6c9.7 27.6 25.5 52.4 45.8 72.6C35.6 359.1 0 416.5 0 482.3C0 498.7 13.3 512 29.7 512L160 512l0-48c0-8.8 7.2-16 16-16s16 7.2 16 16l0 48 226.3 0c16.4 0 29.7-13.3 29.7-29.7c0-65.8-35.6-123.2-88.6-154.1c20.3-20.2 36.2-45 45.8-72.6c15.2-2.5 26.8-15.7 26.8-31.6l0-64c0-15.9-11.6-29.1-26.8-31.6zM319.7 358.5c44.5 18 77.1 59.3 82.6 108.7l-66.4 0 0-19.2c0-17.7-14.3-32-32-32l-160 0c-17.7 0-32 14.3-32 32l0 19.2-66.4 0c5.6-49.5 38.1-90.7 82.6-108.7C156.5 374.7 189.1 384 224 384s67.5-9.3 95.7-25.5zM160 128c-26.5 0-48 21.5-48 48l0 16c0 53 43 96 96 96l32 0c53 0 96-43 96-96l0-16c0-26.5-21.5-48-48-48l-128 0zm39.3 45.5l6 21.2 21.2 6c3.3 .9 5.5 3.9 5.5 7.3s-2.2 6.4-5.5 7.3l-21.2 6-6 21.2c-.9 3.3-3.9 5.5-7.3 5.5s-6.4-2.2-7.3-5.5l-6-21.2-21.2-6c-3.3-.9-5.5-3.9-5.5-7.3s2.2-6.4 5.5-7.3l21.2-6 6-21.2c.9-3.3 3.9-5.5 7.3-5.5s6.4 2.2 7.3 5.5zM256 464a16 16 0 1 1 32 0 16 16 0 1 1 -32 0z"]
  };
  const faBorderCenterH = {
    prefix: 'far',
    iconName: 'border-center-h',
    icon: [448, 512, [], "f89c", "M32 480a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm96 0a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm0-384a32 32 0 1 0 0-64 32 32 0 1 0 0 64zM320 416a32 32 0 1 0 0 64 32 32 0 1 0 0-64zm0-320a32 32 0 1 0 0-64 32 32 0 1 0 0 64zM224 416a32 32 0 1 0 0 64 32 32 0 1 0 0-64zm0-320a32 32 0 1 0 0-64 32 32 0 1 0 0 64zM416 416a32 32 0 1 0 0 64 32 32 0 1 0 0-64zm0-384a32 32 0 1 0 0 64 32 32 0 1 0 0-64zM32 96a32 32 0 1 0 0-64 32 32 0 1 0 0 64zM224 320a32 32 0 1 0 0 64 32 32 0 1 0 0-64zm192 64a32 32 0 1 0 0-64 32 32 0 1 0 0 64zM32 320a32 32 0 1 0 0 64 32 32 0 1 0 0-64zM416 192a32 32 0 1 0 0-64 32 32 0 1 0 0 64zM32 128a32 32 0 1 0 0 64 32 32 0 1 0 0-64zm192 64a32 32 0 1 0 0-64 32 32 0 1 0 0 64zM24 232c-13.3 0-24 10.7-24 24s10.7 24 24 24l400 0c13.3 0 24-10.7 24-24s-10.7-24-24-24L24 232z"]
  };
  const faCat = {
    prefix: 'far',
    iconName: 'cat',
    icon: [576, 512, [128008], "f6be", "M361 0c10.9 0 21.3 4.3 29 12l36 36 44.1 0 36-36c7.7-7.7 18.1-12 29-12c22.6 0 41 18.3 41 41l0 119c0 70.7-57.3 128-128 128s-128-57.3-128-128l0-119c0-22.6 18.3-41 41-41zm7 160c0 44.2 35.8 80 80 80s80-35.8 80-80l0-102.1L501.7 84.3C494.2 91.8 484 96 473.4 96l-50.7 0c-10.6 0-20.8-4.2-28.3-11.7L368 57.9 368 160zm48-32a16 16 0 1 1 0 32 16 16 0 1 1 0-32zm48 16a16 16 0 1 1 32 0 16 16 0 1 1 -32 0zM285 182l4.4-.4c2.2 16.6 7 32.4 13.9 46.9l-14 1.3C188.9 238.9 112 323.1 112 423.9c0 0 0 .1 0 .1c0 22.1 17.9 40 40 40l104 0 0-64c0-17.7-14.3-32-32-32l-8 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l8 0c39.4 0 72.2 28.5 78.8 66l93-54.7c7.4-4.4 16.6-4.4 24.1-.1s12.1 12.2 12.1 20.8l0 96c0 8.8 7.2 16 16 16s16-7.2 16-16l0-128.8c16.9-1.7 33.1-6 48-12.5L512 448c0 35.3-28.7 64-64 64s-64-28.7-64-64l0-54L304 441l0 23 24 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-176 0c-48.6 0-88-39.4-88-88c0 0 0-.1 0-.1l0-239.4c0-20.2-15-37.2-35-39.7l-7.9-1C7.9 142.2-1.5 130.2 .2 117S13.8 94.5 27 96.2l7.9 1c44 5.5 77.1 42.9 77.1 87.3l0 94.5c40-53.7 101.7-90.5 173-97z"]
  };
  const faNarwhal = {
    prefix: 'far',
    iconName: 'narwhal',
    icon: [640, 512, [], "f6fe", "M639.4 20.5c2.3-7.7-1.6-16-9-19.2s-16-.4-20.1 6.5L516 167.3c-15.7-4.7-32.3-7.3-49.6-7.3c-53.1 0-104.4 19.5-144.1 54.8L177.9 343.2c-6.4 5.7-14.6 8.8-23.1 8.8c-19.2 0-34.8-15.6-34.8-34.8l0-13.2 0-45.3 37.9-26.5c21.4-15 34.1-39.4 34.1-65.5l0-54.7c0-6-3.3-11.4-8.6-14.2s-11.7-2.3-16.6 1.1L96 148.5 25.2 98.9c-4.9-3.4-11.3-3.8-16.6-1.1S0 106 0 112l0 54.7c0 26.1 12.7 50.6 34.1 65.5L72 258.7 72 304l0 13.2L72 352c0 88.4 71.6 160 160 160l232 0c97.2 0 176-80 176-177.2c0-50.1-21.2-95.5-55.1-127.5L639.4 20.5zM232 464c-46.3 0-86-28.1-103.1-68.1c8.1 2.7 16.8 4.1 25.9 4.1c20.3 0 39.8-7.4 55-20.9L354.2 250.7c30.9-27.5 70.9-42.7 112.2-42.7C535.4 208 592 265 592 334.8C592 405.9 534.3 464 464 464l-232 0zM496 328a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"]
  };
  const faUserSecret = {
    prefix: 'far',
    iconName: 'user-secret',
    icon: [448, 512, [128373], "f21b", "M224 16c-6.7 0-10.8-2.8-15.5-6.1C201.9 5.4 194 0 176 0c-30.5 0-52 43.7-66 89.4C62.7 98.1 32 112.2 32 128c0 13.4 22.1 25.6 57.7 34.3C88.6 169.4 88 176.6 88 184c0 13.9 2.1 27.4 6 40l-48.6 0C38 224 32 230 32 237.4c0 1.7 .3 3.4 1 5l38.8 96.9C28.2 371.8 0 423.8 0 482.3C0 498.7 13.3 512 29.7 512L168 512l112 0 138.3 0c16.4 0 29.7-13.3 29.7-29.7c0-58.5-28.2-110.4-71.7-143L415 242.4c.6-1.6 1-3.3 1-5c0-7.4-6-13.4-13.4-13.4L354 224c3.9-12.6 6-26.1 6-40c0-7.4-.6-14.6-1.7-21.7c35.6-8.7 57.7-20.9 57.7-34.3c0-15.8-30.7-29.9-78-38.6C324 43.7 302.5 0 272 0c-18 0-25.9 5.4-32.5 9.9c-4.8 3.3-8.8 6.1-15.5 6.1zm44 448l-24.8-99.1c-2.1-8.2-.8-16.9 3.6-24.2l15.7-26.2c25.7-7.6 48.3-22.6 65.2-42.5l23.8 0-19.8 49.5c-8.1 20.2-1.6 43.3 15.9 56.3c27.3 20.4 46.2 51 51.2 86.2L268 464zM185.5 314.5l15.7 26.2c4.4 7.3 5.7 16 3.6 24.2L180 464 49.3 464c4.9-35.2 23.9-65.8 51.2-86.2c17.4-13 23.9-36.1 15.9-56.3L96.5 272l23.8 0c16.9 19.9 39.5 34.9 65.2 42.5zM224 272c-38.2 0-70.7-24.4-82.9-58.4c7.1 6.5 16.5 10.4 26.9 10.4l12.4 0c16.5 0 31.1-10.6 36.3-26.2c2.3-7 12.2-7 14.5 0c5.2 15.6 19.9 26.2 36.3 26.2l12.4 0c10.4 0 19.8-3.9 26.9-10.4c-12.2 34-44.7 58.4-82.9 58.4z"]
  };
  const faOtter = {
    prefix: 'far',
    iconName: 'otter',
    icon: [640, 512, [129446], "f700", "M135.1 185c-4.5-3-7.1-8-7.1-13.3l0-3.7c0-13.3 10.7-24 24-24l16 0c13.3 0 24 10.7 24 24l0 3.7c0 5.3-2.7 10.3-7.1 13.3l-11.8 7.9 8.4 4.2 12.9 6.4c5.9 3 12.4 4.5 19.1 4.5c23.5 0 42.6-19.1 42.6-42.6l0-21.4c0-35.3-28.7-64-64-64l-64 0c-35.3 0-64 28.7-64 64l0 21.4c0 23.5 19.1 42.6 42.6 42.6c6.6 0 13.1-1.5 19.1-4.5l12.9-6.4 8.4-4.2L135.1 185zM160 240l-12.9 6.4c-12.6 6.3-26.5 9.6-40.5 9.6c-5.1 0-10.1-.4-14.9-1.2c9.5 28.6 36.5 49.2 68.3 49.2l64 0c13.3 0 24 10.7 24 24c0 2.8-.5 5.5-1.4 8L352 336c0-11.4 2.4-22.2 6.7-32L328 304c-36.7 0-68.1-22.4-81.3-54.3c-10.3 4.1-21.5 6.3-33.3 6.3c-14.1 0-27.9-3.3-40.5-9.6L160 240zm250.8 16c10.8-45.9 52-80 101.2-80c13.3 0 24 10.7 24 24l0-5.7c0-38.8-24.9-73.3-61.7-85.6l-17.9-6c-12.1-4-18.9-16.8-15.6-29s15.7-19.8 28.1-17.2l16.2 3.4C575.4 78.9 640 158.5 640 250.8c0 120.3-92.6 218.9-210.4 228.5c-4.5 .5-9 .8-13.6 .8l-5.2 0L384 480l-126.4 0C133 480 32 379 32 254.4l0-37.6c-10.1-14.6-16-32.3-16-51.4L16 144l0-1.4C6.7 139.3 0 130.5 0 120c0-13.3 10.7-24 24-24l2.8 0C44.8 58.2 83.3 32 128 32l64 0c44.7 0 83.2 26.2 101.2 64l2.8 0c13.3 0 24 10.7 24 24c0 10.5-6.7 19.3-16 22.6l0 1.4 0 21.4c0 8-1 15.7-3 23.1c12.4-7.9 27.2-12.5 43-12.5l16 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-16 0c-16.6 0-30.2 12.6-31.8 28.7c4.9 2.1 10.2 3.3 15.8 3.3l82.8 0zM99.7 335.8l.1 .2 .3 0-.4-.2zM410.8 432c4.7 0 9.4-.2 14.1-.5c35.6-4.4 63.1-34.7 63.1-71.5l0-56 0-48 0-26.6c-18.9 9-32 28.3-32 50.6c0 13.3-10.7 24-24 24c-17.7 0-32 14.3-32 32l0 24c0 13.3-10.7 24-24 24l-239.8 0c31.8 29.8 74.5 48 121.4 48L384 432l26.8 0zM536 360c0 8.2-.8 16.3-2.4 24c35.9-33.1 58.4-80.6 58.4-133.3c0-16.7-2.8-32.8-8-47.9l0 5.1c0 35.5-19.3 66.6-48 83.2l0 68.8zM96 128a16 16 0 1 1 0 32 16 16 0 1 1 0-32zm112 16a16 16 0 1 1 32 0 16 16 0 1 1 -32 0z"]
  };
  const faEnvelope = {
    prefix: 'far',
    iconName: 'envelope',
    icon: [512, 512, [128386, 9993, 61443], "f0e0", "M64 112c-8.8 0-16 7.2-16 16l0 22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1l0-22.1c0-8.8-7.2-16-16-16L64 112zM48 212.2L48 384c0 8.8 7.2 16 16 16l384 0c8.8 0 16-7.2 16-16l0-171.8L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64l384 0c35.3 0 64 28.7 64 64l0 256c0 35.3-28.7 64-64 64L64 448c-35.3 0-64-28.7-64-64L0 128z"]
  };
  const faCameraPolaroid = {
    prefix: 'far',
    iconName: 'camera-polaroid',
    icon: [576, 512, [], "f8aa", "M512 432L64 432c-8.8 0-16-7.2-16-16l0-32 480 0 0 32c0 8.8-7.2 16-16 16zM68.4 336l32.9-49.3c7-10.5 10.7-22.9 10.7-35.5L112 96c0-8.8 7.2-16 16-16l320 0c8.8 0 16 7.2 16 16l0 155.2c0 12.6 3.7 25 10.7 35.5L507.6 336 68.4 336zM64 480l448 0c35.3 0 64-28.7 64-64l0-44.6c0-12.6-3.7-25-10.7-35.5L514.7 260c-1.8-2.6-2.7-5.7-2.7-8.9L512 96c0-35.3-28.7-64-64-64L128 32C92.7 32 64 60.7 64 96l0 155.2c0 3.2-.9 6.2-2.7 8.9L10.7 335.9C3.7 346.4 0 358.7 0 371.4L0 416c0 35.3 28.7 64 64 64zM408 112a24 24 0 1 0 0 48 24 24 0 1 0 0-48zM288 288a80 80 0 1 0 0-160 80 80 0 1 0 0 160zm-32-80a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"]
  };
  const faGear = {
    prefix: 'far',
    iconName: 'gear',
    icon: [512, 512, [9881, "cog"], "f013", "M256 0c17 0 33.6 1.7 49.8 4.8c7.9 1.5 21.8 6.1 29.4 20.1c2 3.7 3.6 7.6 4.6 11.8l9.3 38.5C350.5 81 360.3 86.7 366 85l38-11.2c4-1.2 8.1-1.8 12.2-1.9c16.1-.5 27 9.4 32.3 15.4c22.1 25.1 39.1 54.6 49.9 86.3c2.6 7.6 5.6 21.8-2.7 35.4c-2.2 3.6-4.9 7-8 10L459 246.3c-4.2 4-4.2 15.5 0 19.5l28.7 27.3c3.1 3 5.8 6.4 8 10c8.2 13.6 5.2 27.8 2.7 35.4c-10.8 31.7-27.8 61.1-49.9 86.3c-5.3 6-16.3 15.9-32.3 15.4c-4.1-.1-8.2-.8-12.2-1.9L366 427c-5.7-1.7-15.5 4-16.9 9.8l-9.3 38.5c-1 4.2-2.6 8.2-4.6 11.8c-7.7 14-21.6 18.5-29.4 20.1C289.6 510.3 273 512 256 512s-33.6-1.7-49.8-4.8c-7.9-1.5-21.8-6.1-29.4-20.1c-2-3.7-3.6-7.6-4.6-11.8l-9.3-38.5c-1.4-5.8-11.2-11.5-16.9-9.8l-38 11.2c-4 1.2-8.1 1.8-12.2 1.9c-16.1 .5-27-9.4-32.3-15.4c-22-25.1-39.1-54.6-49.9-86.3c-2.6-7.6-5.6-21.8 2.7-35.4c2.2-3.6 4.9-7 8-10L53 265.7c4.2-4 4.2-15.5 0-19.5L24.2 218.9c-3.1-3-5.8-6.4-8-10C8 195.3 11 181.1 13.6 173.6c10.8-31.7 27.8-61.1 49.9-86.3c5.3-6 16.3-15.9 32.3-15.4c4.1 .1 8.2 .8 12.2 1.9L146 85c5.7 1.7 15.5-4 16.9-9.8l9.3-38.5c1-4.2 2.6-8.2 4.6-11.8c7.7-14 21.6-18.5 29.4-20.1C222.4 1.7 239 0 256 0zM218.1 51.4l-8.5 35.1c-7.8 32.3-45.3 53.9-77.2 44.6L97.9 120.9c-16.5 19.3-29.5 41.7-38 65.7l26.2 24.9c24 22.8 24 66.2 0 89L59.9 325.4c8.5 24 21.5 46.4 38 65.7l34.6-10.2c31.8-9.4 69.4 12.3 77.2 44.6l8.5 35.1c24.6 4.5 51.3 4.5 75.9 0l8.5-35.1c7.8-32.3 45.3-53.9 77.2-44.6l34.6 10.2c16.5-19.3 29.5-41.7 38-65.7l-26.2-24.9c-24-22.8-24-66.2 0-89l26.2-24.9c-8.5-24-21.5-46.4-38-65.7l-34.6 10.2c-31.8 9.4-69.4-12.3-77.2-44.6l-8.5-35.1c-24.6-4.5-51.3-4.5-75.9 0zM208 256a48 48 0 1 0 96 0 48 48 0 1 0 -96 0zm48 96a96 96 0 1 1 0-192 96 96 0 1 1 0 192z"]
  };
  const faCog = faGear;
  const faClock = {
    prefix: 'far',
    iconName: 'clock',
    icon: [512, 512, [128339, "clock-four"], "f017", "M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120l0 136c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2 280 120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"]
  };
  const faEllipsisVertical = {
    prefix: 'far',
    iconName: 'ellipsis-vertical',
    icon: [128, 512, ["ellipsis-v"], "f142", "M64 368a48 48 0 1 0 0 96 48 48 0 1 0 0-96zm0-160a48 48 0 1 0 0 96 48 48 0 1 0 0-96zM112 96A48 48 0 1 0 16 96a48 48 0 1 0 96 0z"]
  };
  const faEllipsisV = faEllipsisVertical;
  const faHouse = {
    prefix: 'far',
    iconName: 'house',
    icon: [576, 512, [127968, 63498, 63500, "home", "home-alt", "home-lg-alt"], "f015", "M303.5 5.7c-9-7.6-22.1-7.6-31.1 0l-264 224c-10.1 8.6-11.3 23.7-2.8 33.8s23.7 11.3 33.8 2.8L64 245.5 64 432c0 44.2 35.8 80 80 80l288 0c44.2 0 80-35.8 80-80l0-186.5 24.5 20.8c10.1 8.6 25.3 7.3 33.8-2.8s7.3-25.3-2.8-33.8l-264-224zM112 432l0-227.2L288 55.5 464 204.8 464 432c0 17.7-14.3 32-32 32l-48 0 0-152c0-22.1-17.9-40-40-40l-112 0c-22.1 0-40 17.9-40 40l0 152-48 0c-17.7 0-32-14.3-32-32zm128 32l0-144 96 0 0 144-96 0z"]
  };
  const faHome = faHouse;
  const faKiwiBird = {
    prefix: 'far',
    iconName: 'kiwi-bird',
    icon: [576, 512, [], "f535", "M266.4 347.3c31.2-18.9 74.9-43.3 125.9-43.3l55.7 0c44.2 0 80-35.8 80-80s-35.8-80-80-80l-55.7 0c-51 0-94.7-24.4-125.9-43.3C244.7 87.6 219.3 80 192 80C112.5 80 48 144.5 48 224s64.5 144 144 144c27.3 0 52.7-7.6 74.4-20.7zM392.3 352c-36.4 0-69.9 17.6-101.1 36.4c-11.1 6.7-22.9 12.3-35.2 16.7l0 50.9c0 13.3-10.7 24-24 24s-24-10.7-24-24l0-40.7c-5.3 .4-10.6 .7-16 .7c-16.6 0-32.7-2.1-48-6l0 46c0 13.3-10.7 24-24 24s-24-10.7-24-24l0-65.7C38.6 357.1 0 295.1 0 224C0 118 86 32 192 32c36.3 0 70.3 10.1 99.2 27.6C322.4 78.4 355.9 96 392.3 96L448 96c70.7 0 128 57.3 128 128l0 240c0 7.3-4.9 13.7-12 15.5s-14.5-1.3-18-7.7L477.6 348.6c-9.5 2.2-19.4 3.4-29.6 3.4l-55.7 0zM448 200a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"]
  };
  const faHorseHead = {
    prefix: 'far',
    iconName: 'horse-head',
    icon: [640, 512, [], "f7ab", "M405.1 48.7c9.2-3.3 18.9 3.6 18.9 13.4c0 4.2-1.9 8.2-5.1 10.9l-34.3 28.5c-7.3 6.1-10.4 16-7.7 25.2s10.6 15.9 20.1 17.1l1.5 .2c31.3 3.9 58.7 23 73.3 51L520 287.8c11 21.3 7 47.2-9.9 64.2c-10.3 10.3-24.3 16.1-38.8 16.1l-7.4 0c-18.5 0-36-8-48.2-21.9l-26.2-30c-10.3-11.8-28-13.5-40.4-3.9c-27.6 21.5-67.3 17.2-89.7-9.7l-25-30c-8.5-10.2-23.6-11.6-33.8-3.1s-11.6 23.6-3.1 33.8l25 30c25 30 62.3 43.8 98.6 40l43.4 68.2c2.3 3.5 3.5 7.7 3.5 11.9c0 5.8-4.7 10.6-10.6 10.6L136 464c-13.3 0-24-10.7-24-24l0-123.3c0-98.2 61.8-185.7 154.3-218.6L405.1 48.7zm0 367l-21.5-33.8c21 21.7 50 34.1 80.4 34.1l7.4 0c27.3 0 53.5-10.8 72.8-30.1c31.7-31.7 39.2-80.4 18.5-120.2l-48.3-92.8c-14.4-27.8-37.3-49.6-64.7-63C463.8 98 472 80.5 472 62.1c0-43-42.5-73-83-58.6L250.2 52.9C138.6 92.5 64 198.2 64 316.7L64 440c0 39.8 32.2 72 72 72l221.4 0c32.4 0 58.6-26.2 58.6-58.6c0-13.3-3.8-26.4-11-37.6zM360 224a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"]
  };
  const faArrowRightLong = {
    prefix: 'far',
    iconName: 'arrow-right-long',
    icon: [512, 512, ["long-arrow-right"], "f178", "M505 273c9.4-9.4 9.4-24.6 0-33.9L369 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l95 95L24 232c-13.3 0-24 10.7-24 24s10.7 24 24 24l406.1 0-95 95c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0L505 273z"]
  };
  const faLongArrowRight = faArrowRightLong;
  const faBell = {
    prefix: 'far',
    iconName: 'bell',
    icon: [448, 512, [128276, 61602], "f0f3", "M224 0c-17.7 0-32 14.3-32 32l0 19.2C119 66 64 130.6 64 208l0 25.4c0 45.4-15.5 89.5-43.8 124.9L5.3 377c-5.8 7.2-6.9 17.1-2.9 25.4S14.8 416 24 416l400 0c9.2 0 17.6-5.3 21.6-13.6s2.9-18.2-2.9-25.4l-14.9-18.6C399.5 322.9 384 278.8 384 233.4l0-25.4c0-77.4-55-142-128-156.8L256 32c0-17.7-14.3-32-32-32zm0 96c61.9 0 112 50.1 112 112l0 25.4c0 47.9 13.9 94.6 39.7 134.6L72.3 368C98.1 328 112 281.3 112 233.4l0-25.4c0-61.9 50.1-112 112-112zm64 352l-64 0-64 0c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7s18.7-28.3 18.7-45.3z"]
  };
  const faLocationDot = {
    prefix: 'far',
    iconName: 'location-dot',
    icon: [384, 512, ["map-marker-alt"], "f3c5", "M336 192c0-79.5-64.5-144-144-144S48 112.5 48 192c0 12.4 4.5 31.6 15.3 57.2c10.5 24.8 25.4 52.2 42.5 79.9c28.5 46.2 61.5 90.8 86.2 122.6c24.8-31.8 57.8-76.4 86.2-122.6c17.1-27.7 32-55.1 42.5-79.9C331.5 223.6 336 204.4 336 192zm48 0c0 87.4-117 243-168.3 307.2c-12.3 15.3-35.1 15.3-47.4 0C117 435 0 279.4 0 192C0 86 86 0 192 0S384 86 384 192zm-160 0a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm-112 0a80 80 0 1 1 160 0 80 80 0 1 1 -160 0z"]
  };
  const faMapMarkerAlt = faLocationDot;
  const faEarthAmericas = {
    prefix: 'far',
    iconName: 'earth-americas',
    icon: [512, 512, [127758, "earth", "earth-america", "globe-americas"], "f57d", "M256 464C141.1 464 48 370.9 48 256c0-22 3.4-43.1 9.7-63l9.4 16.4c8.3 14.5 21.9 25.2 38 29.8L163 255.7c17.2 4.9 29 20.6 29 38.5l0 39.9c0 11 6.2 21 16 25.9s16 14.9 16 25.9l0 30.4c0 15.9 15.2 27.3 30.5 23c15.9-4.5 28.3-17 32.8-32.8l1.5-5.4c4.6-16.1 15.3-29.7 29.8-38l9.2-5.3c15-8.5 24.2-24.5 24.2-41.7l0-8.3c0-12.7-5.1-24.9-14.1-33.9l-3.9-3.9c-9-9-21.2-14.1-33.9-14.1L257 256c-11.1 0-22.1-2.9-31.8-8.4l-34.5-19.7c-4.3-2.5-7.6-6.5-9.2-11.2c-3.2-9.6 1.1-20 10.2-24.5l5.9-3c6.6-3.3 14.3-3.9 21.3-1.5l23.2 7.7c8.2 2.7 17.2-.4 21.9-7.5c4.7-7 4.2-16.3-1.2-22.8l-13.6-16.3c-10-12-9.9-29.5 .3-41.3l15.7-18.3c8.8-10.3 10.2-25 3.5-36.7l-2.4-4.2c73.6 3.6 137.2 45.6 171.2 106.3L412 164.8c-15.7 6.3-23.8 23.8-18.5 39.8l16.9 50.7c3.5 10.4 12 18.3 22.6 20.9l29.1 7.3C448.7 385.4 361.5 464 256 464zm0 48A256 256 0 1 0 256 0a256 256 0 1 0 0 512z"]
  };
  const faGlobeAmericas = faEarthAmericas;
  const faLink = {
    prefix: 'far',
    iconName: 'link',
    icon: [640, 512, [128279, "chain"], "f0c1", "M580.3 267.2c56.2-56.2 56.2-147.3 0-203.5C526.8 10.2 440.9 7.3 383.9 57.2l-6.1 5.4c-10 8.7-11 23.9-2.3 33.9s23.9 11 33.9 2.3l6.1-5.4c38-33.2 95.2-31.3 130.9 4.4c37.4 37.4 37.4 98.1 0 135.6L433.1 346.6c-37.4 37.4-98.2 37.4-135.6 0c-35.7-35.7-37.6-92.9-4.4-130.9l4.7-5.4c8.7-10 7.7-25.1-2.3-33.9s-25.1-7.7-33.9 2.3l-4.7 5.4c-49.8 57-46.9 142.9 6.6 196.4c56.2 56.2 147.3 56.2 203.5 0L580.3 267.2zM59.7 244.8C3.5 301 3.5 392.1 59.7 448.2c53.6 53.6 139.5 56.4 196.5 6.5l6.1-5.4c10-8.7 11-23.9 2.3-33.9s-23.9-11-33.9-2.3l-6.1 5.4c-38 33.2-95.2 31.3-130.9-4.4c-37.4-37.4-37.4-98.1 0-135.6L207 165.4c37.4-37.4 98.1-37.4 135.6 0c35.7 35.7 37.6 92.9 4.4 130.9l-5.4 6.1c-8.7 10-7.7 25.1 2.3 33.9s25.1 7.7 33.9-2.3l5.4-6.1c49.9-57 47-142.9-6.5-196.5c-56.2-56.2-147.3-56.2-203.5 0L59.7 244.8z"]
  };
  const faMagnifyingGlass = {
    prefix: 'far',
    iconName: 'magnifying-glass',
    icon: [512, 512, [128269, "search"], "f002", "M368 208A160 160 0 1 0 48 208a160 160 0 1 0 320 0zM337.1 371.1C301.7 399.2 256.8 416 208 416C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208c0 48.8-16.8 93.7-44.9 129.1L505 471c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L337.1 371.1z"]
  };
  const faSearch = faMagnifyingGlass;
  const faChevronDown = {
    prefix: 'far',
    iconName: 'chevron-down',
    icon: [512, 512, [], "f078", "M239 401c9.4 9.4 24.6 9.4 33.9 0L465 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-175 175L81 175c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9L239 401z"]
  };
  const faCircleUser = {
    prefix: 'far',
    iconName: 'circle-user',
    icon: [512, 512, [62142, "user-circle"], "f2bd", "M406.5 399.6C387.4 352.9 341.5 320 288 320l-64 0c-53.5 0-99.4 32.9-118.5 79.6C69.9 362.2 48 311.7 48 256C48 141.1 141.1 48 256 48s208 93.1 208 208c0 55.7-21.9 106.2-57.5 143.6zm-40.1 32.7C334.4 452.4 296.6 464 256 464s-78.4-11.6-110.5-31.7c7.3-36.7 39.7-64.3 78.5-64.3l64 0c38.8 0 71.2 27.6 78.5 64.3zM256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-272a40 40 0 1 1 0-80 40 40 0 1 1 0 80zm-88-40a88 88 0 1 0 176 0 88 88 0 1 0 -176 0z"]
  };
  const faUserCircle = faCircleUser;
  const faExpand = {
    prefix: 'far',
    iconName: 'expand',
    icon: [448, 512, [], "f065", "M136 32c13.3 0 24 10.7 24 24s-10.7 24-24 24L48 80l0 88c0 13.3-10.7 24-24 24s-24-10.7-24-24L0 56C0 42.7 10.7 32 24 32l112 0zM0 344c0-13.3 10.7-24 24-24s24 10.7 24 24l0 88 88 0c13.3 0 24 10.7 24 24s-10.7 24-24 24L24 480c-13.3 0-24-10.7-24-24L0 344zM424 32c13.3 0 24 10.7 24 24l0 112c0 13.3-10.7 24-24 24s-24-10.7-24-24l0-88-88 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l112 0zM400 344c0-13.3 10.7-24 24-24s24 10.7 24 24l0 112c0 13.3-10.7 24-24 24l-112 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l88 0 0-88z"]
  };
  const faXmark = {
    prefix: 'far',
    iconName: 'xmark',
    icon: [384, 512, [128473, 10005, 10006, 10060, 215, "close", "multiply", "remove", "times"], "f00d", "M345 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L73 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L39 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L311 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L345 137z"]
  };
  const faTimes = faXmark;
  const faArrowRightFromLine = {
    prefix: 'far',
    iconName: 'arrow-right-from-line',
    icon: [448, 512, [8614, "arrow-from-left"], "f343", "M48 88c0-13.3-10.7-24-24-24S0 74.7 0 88L0 424c0 13.3 10.7 24 24 24s24-10.7 24-24L48 88zM440.4 273.5c4.8-4.5 7.6-10.9 7.6-17.5s-2.7-12.9-7.6-17.5l-136-128c-9.7-9.1-24.8-8.6-33.9 1s-8.6 24.8 1 33.9L363.5 232 280 232l-128 0c-13.3 0-24 10.7-24 24s10.7 24 24 24l128 0 83.5 0-91.9 86.5c-9.7 9.1-10.1 24.3-1 33.9s24.3 10.1 33.9 1l136-128z"]
  };
  const faArrowFromLeft = faArrowRightFromLine;
  const faBellSlash = {
    prefix: 'far',
    iconName: 'bell-slash',
    icon: [640, 512, [128277, 61943], "f1f6", "M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L542.6 400c2.7-7.8 1.3-16.5-3.9-23l-14.9-18.6C495.5 322.9 480 278.8 480 233.4l0-33.4c0-75.8-55.5-138.6-128-150.1L352 32c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 17.9c-43.9 7-81.5 32.7-104.4 68.7L38.8 5.1zM221.7 148.4C239.6 117.1 273.3 96 312 96l8 0 8 0c57.4 0 104 46.6 104 104l0 33.4c0 32.7 6.4 64.8 18.7 94.5L221.7 148.4zM406.2 416l-60.9-48-176.9 0c21.2-32.8 34.4-70.3 38.4-109.1L160 222.1l0 11.4c0 45.4-15.5 89.5-43.8 124.9L101.3 377c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6l286.2 0zM384 448l-64 0-64 0c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7s18.7-28.3 18.7-45.3z"]
  };
  const faChevronLeft = {
    prefix: 'far',
    iconName: 'chevron-left',
    icon: [320, 512, [9001], "f053", "M15 239c-9.4 9.4-9.4 24.6 0 33.9L207 465c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L65.9 256 241 81c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L15 239z"]
  };
  const faChevronRight = {
    prefix: 'far',
    iconName: 'chevron-right',
    icon: [320, 512, [9002], "f054", "M305 239c9.4 9.4 9.4 24.6 0 33.9L113 465c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l175-175L79 81c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L305 239z"]
  };
  const faThumbtack = {
    prefix: 'far',
    iconName: 'thumbtack',
    icon: [384, 512, [128204, 128392, "thumb-tack"], "f08d", "M134.6 51.7L123.8 192.6c-1.1 14.6-8.8 27.8-20.9 36C79 244.8 61.1 269.4 53.8 298.9L52.5 304 168 304l0-88c0-13.3 10.7-24 24-24s24 10.7 24 24l0 88 115.5 0-1.3-5.1c-7.4-29.5-25.2-54.1-49.1-70.2c-12.1-8.2-19.8-21.5-20.9-36L249.4 51.7c-.1-1.2-.1-2.5-.1-3.7L134.8 48c0 1.2 0 2.5-.1 3.7zM168 352L32 352c-9.9 0-19.2-4.5-25.2-12.3S-1.4 321.8 1 312.2l6.2-25c10.3-41.3 35.4-75.7 68.7-98.3L83.1 96l3.7-48L56 48c-4.4 0-8.6-1.2-12.2-3.3C36.8 40.5 32 32.8 32 24C32 10.7 42.7 0 56 0L86.8 0 297.2 0 328 0c13.3 0 24 10.7 24 24c0 8.8-4.8 16.5-11.8 20.7c-3.6 2.1-7.7 3.3-12.2 3.3l-30.8 0 3.7 48 7.1 92.9c33.3 22.6 58.4 57.1 68.7 98.3l6.2 25c2.4 9.6 .2 19.7-5.8 27.5s-15.4 12.3-25.2 12.3l-136 0 0 136c0 13.3-10.7 24-24 24s-24-10.7-24-24l0-136z"]
  };
  const faWhale = {
    prefix: 'far',
    iconName: 'whale',
    icon: [640, 512, [128011], "f72c", "M175.8 13.4c-1-5.9-5.2-10.8-10.9-12.6s-12-.3-16.2 3.9L108 45.4C89.7 63.7 81.4 89.6 85.6 115.1l7.9 47.4L79.2 176.8 43.6 212.4C15.7 240.3 0 278.2 0 317.6C0 407.3 72.7 480 162.4 480L544 480c53 0 96-43 96-96l0-108.3C640 194.1 573.9 128 492.3 128c-80.5 0-158.6 27.4-221.4 77.7L139 311.2c-7.1 5.7-15.9 8.8-25 8.8l-1 0c-19 0-35.2-13.7-38.4-32.5c-1.7-9.9 .6-20.1 6.3-28.4l35.4-51.4 9.1-13.2 47.6 7.9c25.5 4.2 51.5-4.1 69.7-22.3l40.7-40.7c4.2-4.2 5.8-10.5 3.9-16.2s-6.7-9.9-12.6-10.9L189.9 98.1 175.8 13.4zM50.5 341.5C66.4 358 88.7 368 113 368l1 0c20 0 39.4-6.8 55-19.3L300.9 243.1C355.2 199.7 422.7 176 492.3 176c55 0 99.7 44.6 99.7 99.7L592 384c0 26.5-21.5 48-48 48l-381.6 0c-55 0-100.9-38.8-111.9-90.5zM392 328a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"]
  };
  const faQrcode = {
    prefix: 'far',
    iconName: 'qrcode',
    icon: [448, 512, [], "f029", "M144 80l0 96-96 0 0-96 96 0zM48 32C21.5 32 0 53.5 0 80l0 96c0 26.5 21.5 48 48 48l96 0c26.5 0 48-21.5 48-48l0-96c0-26.5-21.5-48-48-48L48 32zm96 304l0 96-96 0 0-96 96 0zM48 288c-26.5 0-48 21.5-48 48l0 96c0 26.5 21.5 48 48 48l96 0c26.5 0 48-21.5 48-48l0-96c0-26.5-21.5-48-48-48l-96 0zM304 80l96 0 0 96-96 0 0-96zm-48 0l0 96c0 26.5 21.5 48 48 48l96 0c26.5 0 48-21.5 48-48l0-96c0-26.5-21.5-48-48-48l-96 0c-26.5 0-48 21.5-48 48zM72 120l0 16c0 8.8 7.2 16 16 16l16 0c8.8 0 16-7.2 16-16l0-16c0-8.8-7.2-16-16-16l-16 0c-8.8 0-16 7.2-16 16zM88 360c-8.8 0-16 7.2-16 16l0 16c0 8.8 7.2 16 16 16l16 0c8.8 0 16-7.2 16-16l0-16c0-8.8-7.2-16-16-16l-16 0zM328 120l0 16c0 8.8 7.2 16 16 16l16 0c8.8 0 16-7.2 16-16l0-16c0-8.8-7.2-16-16-16l-16 0c-8.8 0-16 7.2-16 16zM256 304l0 160c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-64c0-8.8 7.2-16 16-16s16 7.2 16 16s7.2 16 16 16l64 0c8.8 0 16-7.2 16-16l0-96c0-8.8-7.2-16-16-16s-16 7.2-16 16s-7.2 16-16 16l-32 0c-8.8 0-16-7.2-16-16s-7.2-16-16-16l-64 0c-8.8 0-16 7.2-16 16zM368 448a16 16 0 1 0 0 32 16 16 0 1 0 0-32zm64 0a16 16 0 1 0 0 32 16 16 0 1 0 0-32z"]
  };
  const faShield = {
    prefix: 'far',
    iconName: 'shield',
    icon: [512, 512, [128737, "shield-blank"], "f132", "M256 49.4L73 127c-5.9 2.5-9.1 7.8-9 12.8c.5 91.4 38.4 249.3 186.4 320.1c3.6 1.7 7.8 1.7 11.3 0C409.7 389 447.6 231.2 448 139.7c0-5-3.1-10.2-9-12.8L256 49.4zM269.4 2.9L457.7 82.8c22 9.3 38.4 31 38.3 57.2c-.5 99.2-41.3 280.7-213.6 363.2c-16.7 8-36.1 8-52.8 0C57.3 420.7 16.5 239.2 16 140c-.1-26.2 16.3-47.9 38.3-57.2L242.7 2.9C246.8 1 251.4 0 256 0s9.2 1 13.4 2.9z"]
  };
  const faUpRightAndDownLeftFromCenter = {
    prefix: 'far',
    iconName: 'up-right-and-down-left-from-center',
    icon: [512, 512, ["expand-alt"], "f424", "M323.3 0C303.8 0 288 15.8 288 35.3c0 9.4 3.7 18.3 10.3 25L326.1 88 264 150.1c-18.7 18.7-18.7 49.1 0 67.9L294.1 248c18.7 18.7 49.1 18.7 67.9 0L424 185.9l27.7 27.7c6.6 6.6 15.6 10.3 25 10.3c19.5 0 35.3-15.8 35.3-35.3L512 40c0-22.1-17.9-40-40-40L323.3 0zM377 71l-23-23L464 48l0 110.1-23-23c-9.4-9.4-24.6-9.4-33.9 0l-79 79L297.9 184l79-79c9.4-9.4 9.4-24.6 0-33.9zM188.7 512c19.5 0 35.3-15.8 35.3-35.3c0-9.4-3.7-18.3-10.3-25L185.9 424 248 361.9c18.7-18.7 18.7-49.1 0-67.9L217.9 264c-18.7-18.7-49.1-18.7-67.9 0L88 326.1 60.3 298.3c-6.6-6.6-15.6-10.3-25-10.3C15.8 288 0 303.8 0 323.3L0 472c0 22.1 17.9 40 40 40l148.7 0zM135 441l23 23L48 464l0-110.1 23 23c9.4 9.4 24.6 9.4 33.9 0l79-79L214.1 328l-79 79c-9.4 9.4-9.4 24.6 0 33.9z"]
  };
  const faExpandAlt = faUpRightAndDownLeftFromCenter;
  const faTurtle = {
    prefix: 'far',
    iconName: 'turtle',
    icon: [576, 512, [128034], "f726", "M336 240l0-32c0-70.7-57.3-128-128-128S80 137.3 80 208l0 32 256 0zm48-32l0 41.2c0 21.4-17.4 38.8-38.8 38.8L70.8 288C49.4 288 32 270.6 32 249.2L32 208c0-97.2 78.8-176 176-176s176 78.8 176 176zm96 80c-8.3 0-16.3-1.6-23.6-4.5C440.3 328 400.4 361 352 367l0 1 0 72c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-72-32 0 0 72c0 22.1-17.9 40-40 40l-48 0c-22.1 0-40-17.9-40-40l0-72-40 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l40 0 48 0 32 0 48 0 32 0 48 0 32 0 32 0c44.2 0 80-35.8 80-80l0-16 0-32 0-16c0-35.3 28.7-64 64-64c53 0 96 43 96 96l0 16c0 35.3-28.7 64-64 64l-32 0zM272 368l0 64 32 0 0-64-32 0zm-160 0l0 64 32 0 0-64-32 0zM496 224a16 16 0 1 0 0-32 16 16 0 1 0 0 32z"]
  };
  const faLinkSlash = {
    prefix: 'far',
    iconName: 'link-slash',
    icon: [640, 512, ["chain-broken", "chain-slash", "unlink"], "f127", "M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L489.3 358.2l91-91c56.2-56.2 56.2-147.3 0-203.5C526.8 10.2 440.9 7.3 383.9 57.2l-6.1 5.4c-10 8.7-11 23.9-2.3 33.9s23.9 11 33.9 2.3l6.1-5.4c38-33.2 95.2-31.3 130.9 4.4c37.4 37.4 37.4 98.1 0 135.6l-95.1 95.1-45.5-35.7c24.2-53.3 14.1-117.9-29.3-161.3c-52.1-52.1-134.4-55.9-190.8-11.2L38.8 5.1zm186.3 146c36.9-22.3 85.6-17.6 117.4 14.3c26 26 34 63.3 23.7 96.4L225.1 151.1zM352.6 373.8c-20.2-2.7-39.7-11.7-55.2-27.3c-9.8-9.8-17-21.2-21.7-33.3l-54.2-42.7c-2.2 39.6 11.9 79.9 41.9 109.9c38.8 38.8 94.2 50.8 143.4 36l-54.2-42.7zm-236-186L59.7 244.8C3.5 301 3.5 392.1 59.7 448.2c53.6 53.6 139.5 56.4 196.5 6.5l6.1-5.4c10-8.7 11-23.9 2.3-33.9s-23.9-11-33.9-2.3l-6.1 5.4c-38 33.2-95.2 31.3-130.9-4.4c-37.4-37.4-37.4-98.1 0-135.6l60.9-60.9-38-29.9z"]
  };
  const faUnlink = faLinkSlash;
  const faPaperPlane = {
    prefix: 'far',
    iconName: 'paper-plane',
    icon: [512, 512, [61913], "f1d8", "M16.1 260.2c-22.6 12.9-20.5 47.3 3.6 57.3L160 376l0 103.3c0 18.1 14.6 32.7 32.7 32.7c9.7 0 18.9-4.3 25.1-11.8l62-74.3 123.9 51.6c18.9 7.9 40.8-4.5 43.9-24.7l64-416c1.9-12.1-3.4-24.3-13.5-31.2s-23.3-7.5-34-1.4l-448 256zm52.1 25.5L409.7 90.6 190.1 336l1.2 1L68.2 285.7zM403.3 425.4L236.7 355.9 450.8 116.6 403.3 425.4z"]
  };
  const faUnicorn = {
    prefix: 'far',
    iconName: 'unicorn',
    icon: [640, 512, [129412], "f727", "M426.8 .6c1.7-.4 3.4-.6 5.2-.6l8 0 32 0 .5 0c2.5 0 5 .1 7.5 .3l0-.3 56 0c13.3 0 24 10.7 24 24c0 8.5-4.4 16-11.1 20.3c3.5 6.1 6.3 12.7 8.3 19.7l73.5 0c5.1 0 9.3 4.2 9.3 9.3c0 4-2.6 7.6-6.4 8.8l-69.8 23.3 5.6 39.4c5.2 36.7-15.3 72.2-49.7 86c-18.3 7.3-38 7.5-55.8 1.5l0 7.8 0 .7 0 1.6c0 33.2-12.2 62.9-32 85.5L432 464c0 26.5-21.5 48-48 48l-48 0c-26.5 0-48-21.5-48-48l0-99.5-51.8-15.1c-1 3.8-2.2 7.5-3.6 11.2l-12.1 32.3c-.6 1.6-.7 3.3-.2 5l14.8 53.3c8.5 30.6-14.5 60.8-46.2 60.8l-49.8 0c-21.6 0-40.5-14.4-46.2-35.2L81.6 436.4c-8.6-31-7.2-63.9 4.1-94.1l8.2-21.7L72 265.9c-5.3-13.3-8-27.4-8-41.7c0-2.9 .1-5.7 .3-8.5C54.4 223 48 234.8 48 248l0 64c0 13.3-10.7 24-24 24s-24-10.7-24-24l0-64c0-47.3 37.3-85.9 84.1-87.9c20.3-29.1 54-48.1 92.1-48.1l22.6 0 33.1 0 61.3 0C309.8 51.5 362.6 6.1 426.8 .6zM416 239l0-55c0-10.2 6.4-19.3 16.1-22.6s20.3-.3 26.7 7.7l5.9 7.3c8.9 11.1 24.1 15.1 37.3 9.8c13.9-5.5 22.2-19.9 20-34.7l-9.9-69.2C509.3 62.6 492.4 48 472.5 48l-.5 0-32 0-4.6 0c-.6 .1-1.3 .2-2 .2c-50 3.1-90.5 41.6-96.6 90.8c-1.5 12-11.7 21-23.8 21l-81 0-33.1 0-22.6 0c-35.5 0-64.2 28.8-64.2 64.2c0 8.2 1.6 16.3 4.6 23.9l21.9 54.6c4.4 11.1 4.6 23.5 .4 34.7l-8.2 21.7c-7.7 20.6-8.7 43.1-2.8 64.4L139.1 464l49.8 0-14.8-53.3c-3.2-11.4-2.6-23.6 1.5-34.6l12.1-32.3c2.8-7.5 4.3-15.4 4.3-23.5c0-.4 0-.9 0-1.3c-.2-7.6 3.3-14.9 9.4-19.5s14-6.1 21.3-4l88 25.7c2.1 .6 4.2 1.1 6.3 1.6c11.1 2.3 19 12.1 19 23.5L336 464l48 0 0-145.8c0-6.5 2.6-12.7 7.3-17.2c15.1-14.8 24.7-35.3 24.7-58.8c0-.6 0-1.2 0-1.7c0-.5 0-1 0-1.5zM464 80a16 16 0 1 1 0 32 16 16 0 1 1 0-32z"]
  };
  const faBrush = {
    prefix: 'far',
    iconName: 'brush',
    icon: [384, 512, [], "f55d", "M48 288L48 64c0-8.8 7.2-16 16-16l32 0 0 96c0 8.8 7.2 16 16 16s16-7.2 16-16l0-96 64 0 0 64c0 8.8 7.2 16 16 16s16-7.2 16-16l0-64 96 0c8.8 0 16 7.2 16 16l0 224L48 288zM0 320c0 35.3 28.7 64 64 64l64 0 0 64c0 35.3 28.7 64 64 64s64-28.7 64-64l0-64 64 0c35.3 0 64-28.7 64-64l0-256c0-35.3-28.7-64-64-64L64 0C28.7 0 0 28.7 0 64L0 320zM192 432a16 16 0 1 1 0 32 16 16 0 1 1 0-32z"]
  };
  const faUserLarge = {
    prefix: 'far',
    iconName: 'user-large',
    icon: [512, 512, ["user-alt"], "f406", "M352 144a96 96 0 1 0 -192 0 96 96 0 1 0 192 0zm-240 0a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zM49.3 464l413.4 0c-8.3-54.4-55.3-96-112-96l-189.4 0c-56.7 0-103.6 41.6-112 96zM0 481.3C0 392.2 72.2 320 161.3 320l189.4 0C439.8 320 512 392.2 512 481.3c0 17-13.8 30.7-30.7 30.7L30.7 512C13.8 512 0 498.2 0 481.3z"]
  };
  const faUserAlt = faUserLarge;
  const faNoteSticky = {
    prefix: 'far',
    iconName: 'note-sticky',
    icon: [448, 512, [62026, "sticky-note"], "f249", "M64 80c-8.8 0-16 7.2-16 16l0 320c0 8.8 7.2 16 16 16l224 0 0-80c0-17.7 14.3-32 32-32l80 0 0-224c0-8.8-7.2-16-16-16L64 80zM288 480L64 480c-35.3 0-64-28.7-64-64L0 96C0 60.7 28.7 32 64 32l320 0c35.3 0 64 28.7 64 64l0 224 0 5.5c0 17-6.7 33.3-18.7 45.3l-90.5 90.5c-12 12-28.3 18.7-45.3 18.7l-5.5 0z"]
  };
  const faStickyNote = faNoteSticky;

  exports.faArrowFromLeft = faArrowFromLeft;
  exports.faArrowFromRight = faArrowFromRight;
  exports.faBadgerHoney = faBadgerHoney;
  exports.faBell = faBell;
  exports.faBellSlash = faBellSlash;
  exports.faBorderCenterH = faBorderCenterH;
  exports.faBorderCenterV = faBorderCenterV;
  exports.faBrush = faBrush;
  exports.faCalendarAlt = faCalendarAlt;
  exports.faCameraPolaroid = faCameraPolaroid;
  exports.faCat = faCat;
  exports.faCatSpace = faCatSpace;
  exports.faChevronDown = faChevronDown;
  exports.faChevronLeft = faChevronLeft;
  exports.faChevronRight = faChevronRight;
  exports.faChevronUp = faChevronUp;
  exports.faCity = faCity;
  exports.faClock = faClock;
  exports.faCode = faCode;
  exports.faCog = faCog;
  exports.faCommentAltLines = faCommentAltLines;
  exports.faCompress = faCompress;
  exports.faElephant = faElephant;
  exports.faEllipsisV = faEllipsisV;
  exports.faEnvelope = faEnvelope;
  exports.faExpand = faExpand;
  exports.faExpandAlt = faExpandAlt;
  exports.faFilePlus = faFilePlus;
  exports.faFlag = faFlag;
  exports.faGlobeAmericas = faGlobeAmericas;
  exports.faHeart = faHeart;
  exports.faHeartBroken = faHeartBroken;
  exports.faHeartRate = faHeartRate;
  exports.faHome = faHome;
  exports.faHorseHead = faHorseHead;
  exports.faKey = faKey;
  exports.faKiwiBird = faKiwiBird;
  exports.faLink = faLink;
  exports.faLongArrowRight = faLongArrowRight;
  exports.faMapMarkerAlt = faMapMarkerAlt;
  exports.faMonkey = faMonkey;
  exports.faNarwhal = faNarwhal;
  exports.faOtter = faOtter;
  exports.faPaperPlane = faPaperPlane;
  exports.faPegasus = faPegasus;
  exports.faPencil = faPencil;
  exports.faPlusSquare = faPlusSquare;
  exports.faQrcode = faQrcode;
  exports.faRabbit = faRabbit;
  exports.faReply = faReply;
  exports.faSearch = faSearch;
  exports.faShield = faShield;
  exports.faShieldCheck = faShieldCheck;
  exports.faSignInAlt = faSignInAlt;
  exports.faSignOut = faSignOut;
  exports.faSquirrel = faSquirrel;
  exports.faStickyNote = faStickyNote;
  exports.faThumbtack = faThumbtack;
  exports.faTimes = faTimes;
  exports.faTrashAlt = faTrashAlt;
  exports.faTurtle = faTurtle;
  exports.faUnicorn = faUnicorn;
  exports.faUnlink = faUnlink;
  exports.faUserAlt = faUserAlt;
  exports.faUserAstronaut = faUserAstronaut;
  exports.faUserCircle = faUserCircle;
  exports.faUserCowboy = faUserCowboy;
  exports.faUserNinja = faUserNinja;
  exports.faUserSecret = faUserSecret;
  exports.faWhale = faWhale;

  Object.defineProperty(exports, '__esModule', { value: true });

}));
